import React, { useState, useEffect, useCallback } from "react";
import { ImUser } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { CircularProgress } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "../../../shared/Form";
import {
  useGetDriversQuery,
  useLazyGetDriversQuery,
  useLazyGetInterestedDriversQuery,
} from "../../../../api/drivers";
import CarIcon from "../../svgIcons/carIcons";
import { useChangeStatusMutation } from "../../../../api/orders";
import { actions } from "../../../../redux/store/store";
import { checkForError } from "../../../../constants/extras/errorHandlers";
import "./style.scss";
import { sendSuccessMessage } from "../../../../constants/extras/sendSuccessMsg";
import {
  checkForIsDriverNew,
  handleStatusesBadge,
} from "../../../../constants/extras/statusesBadge";
import { handleCarText } from "../../../../constants/extras/handleDriverName";
import { handleNumberwithPadding } from "../../../../constants/extras/handleNumbers";
import {
  convertToCapitalize,
  removeDash,
} from "../../../../constants/extras/convertToCapitalize";
import moment from "moment";
import CompanySelect from "../../companySelect";

const AssignDriver = (props) => {
  const order = props && props?.order && props?.order;
  const metro = props && props?.form && props?.form?.getValues("metro");

  const id = order?._id;
  const form = useForm({
    defaultValues: {
      company: "",
    },
  });
  const company = form.watch("company");
  const [driversOption, setDriversOption] = useState("interested");
  const { data: allDrivers, isFetching: loadingAllDrivers } =
    useGetDriversQuery(
      { metro: metro && metro, company: company?.value, status: "approved" },
      {
        refetchOnMountOrArgChange: true,
        skip: driversOption !== "all",
      }
    );

  const [triggerGetDrivers, { data: availableDrivers, isFetching }] =
    useLazyGetDriversQuery();
  const [
    triggerGetInterestedDrivers,
    { data: interestedDrivers, isFetching: loadingInterestedDrivers },
  ] = useLazyGetInterestedDriversQuery({ refetchOnReconnect: true });
  const [changeStatus, { isLoading: isChangingStatus }] =
    useChangeStatusMutation();

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [driverId, setDriverId] = useState([]);

  const handleDriversOptions = (e) => {
    setDriversOption(e.target.value);
  };

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (
      order &&
      order?.status &&
      ["created", "rejected", "assigned"].includes(order?.status) &&
      driversOption === "align"
    ) {
      triggerGetDrivers({ forOrderId: id, company: company?.value  });
    } else if (
      order &&
      order?.status &&
      ["created", "rejected", "assigned"].includes(order?.status) &&
      driversOption === "interested"
    ) {
      triggerGetInterestedDrivers({ forOrderId: id });
    }
  }, [
    order,
    triggerGetDrivers,
    triggerGetInterestedDrivers,
    driversOption,
    id,
    company
  ]);
  const { control } = form;
  useEffect(() => {
    if (
      driversOption === "align"
        ? !availableDrivers
        : driversOption === "all"
          ? !allDrivers
          : !interestedDrivers
    )
      return;

    setFilteredDrivers(
      driversOption === "align"
        ? availableDrivers
        : driversOption === "all"
          ? allDrivers
          : interestedDrivers
    );
    if (searchQuery && searchQuery?.length > 2) {
      setFilteredDrivers(
        (driversOption === "align"
          ? availableDrivers
          : driversOption === "all"
            ? allDrivers
            : interestedDrivers
        ).filter((item) => {
          const { firstName, lastName } = item || {};
          return (
            (firstName || lastName) &&
            firstName
              .concat(" ", lastName)
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          );
        })
      );
    }
  }, [
    availableDrivers,
    searchQuery,
    driversOption,
    interestedDrivers,
    allDrivers,
  ]);

  useEffect(() => setDriverId(props?.driverId), [props.driverId]);

  const handleDriverData = () => {
    if (driversOption === "all") return loadingAllDrivers;
    else if (driversOption === "align") return isFetching;
    else return loadingInterestedDrivers;
  };

  const handleAssignDriverShift = async (driver) => {
    const { data: result } = await changeStatus({
      status: `${order?.status === "assigned" ? "reassign" : "assign"}`,
      _id: order?._id,
      driverId: driver?._id,
    });

    if (result?.status === "assigned") {
      sendSuccessMessage(`Shift assigned successfully`);
      props.onClick(driver);
      actions.refetch.setIsDataRefetch(true);
    } else checkForError(result?.error);
  };
  return (
    <main id="select__driver__container">
      <section
        id="HOW__TO__SHOW__DRIVERS__LIST"
        style={{
          display: "flex",
          gap: "2%",
          alignItems: "center",
          height: "2rem",
          marginBottom: "0.3rem",
        }}
      >
        <div className="d-flex align-items-center">
          <input
            type="radio"
            id="interested__drivers"
            name="how__to__show__drivers"
            value={"interested"}
            onChange={handleDriversOptions}
            checked={driversOption === "interested"}
          />
          <label htmlFor="interested__drivers" className="m-auto">
            Interested Drivers
          </label>
        </div>
        <div className="d-flex align-items-center">
          <input
            type="radio"
            id="align__drivers"
            name="how__to__show__drivers"
            value="align"
            onChange={handleDriversOptions}
          />
          <label htmlFor="align__drivers" className="m-auto">
            Aligned Drivers
          </label>
        </div>

        <div className="d-flex align-items-center">
          <input
            type="radio"
            id="all__drivers"
            name="how__to__show__drivers"
            value="all"
            onChange={handleDriversOptions}
          />
          <label htmlFor="all__drivers" className="m-auto">
            All Drivers From Shift Metro
          </label>
        </div>
        {order &&
          ["created", "rejected"].includes(order?.status) &&
          driversOption !== "interested" && (
            <React.Fragment>
              <div style={{ width: "22%" }}>
                <Controller
                  control={control}
                  name="company"
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <React.Fragment>
                      <CompanySelect
                        value={value || ""}
                        onChange={(e, data) => {
                          onChange(data);

                        }}
                        ref={ref}
                        isError={error && true}
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage error={error} />
                    </React.Fragment>
                  )}
                />
              </div>

              <button
                className="btn btn_accent customized-details-btn"
                type="button"
                onClick={async () => {
                  const { data: notificationData } = await changeStatus({
                    status: "sendNotification",
                    _id: id && id,
                    sendTo:
                      driversOption === "align"
                        ? "alignedDrivers"
                        : "shiftMetroDrivers",
                    company: company?.value
                  });
                  if (notificationData?.status === 200) {
                    sendSuccessMessage(notificationData?.message);
                  } else {
                    checkForError(notificationData?.message);
                  }
                }}
                style={{ margin: "0 0 0.4rem" }}
                disabled={isChangingStatus}
              >
                Send Notification
              </button>
            </React.Fragment>
          )}
      </section>

      {driversOption && driversOption.length > 0 && (
        <React.Fragment>
          <div style={{ position: "relative" }}>
            <input
              type="text"
              onChange={handleSearchQuery}
              value={searchQuery}
              name="searchQuery"
              className="search__input"
              placeholder="Search Driver Here..."
              disabled={
                !Array.isArray(filteredDrivers) ||
                isFetching ||
                loadingAllDrivers ||
                loadingInterestedDrivers
              }
              autoComplete="off"
            />
            <HighlightOffIcon
              className="clear__btn"
              onClick={() => setSearchQuery("")}
            />
          </div>
          {
            <div className="available-drivers scroll__view">
              {driversOption &&
                driversOption.length > 0 &&
                handleDriverData() ? (
                <div className="inner__scroll__view__container">
                  <CircularProgress className="loading-progress" />
                </div>
              ) : filteredDrivers &&
                Array.isArray(filteredDrivers) &&
                filteredDrivers?.length > 0 ? (
                filteredDrivers.map(
                  (driver, idx) =>
                    driver?._id !== driverId && (
                      <div
                        className="driver__table"
                        key={driver?._id}
                        title={driver?.carType?.title}
                        style={{
                          borderBottom:
                            idx < filteredDrivers?.length - 1
                              ? "1px dashed #dadada"
                              : "",
                        }}
                      >
                        <div className="driver__table__first__cell">
                          <DriverName driver={driver} driversOption={driversOption} />
                        </div>
                        <div className="driver__table__seccond__cell">
                          {driver.company?.companyCode ? driver.company.companyCode : ""} {driver.company?.companyCode && driver.company.companyName ? "-" : ""}
                          {driver.company?.companyName ? driver.company.companyName : ""}
                        </div>
                        <div className="driver__table__middle__cell">
                          <div className="available-drivers__inner">
                            <div className="available-drivers__icon-holder">
                              <CarIcon
                                type={driver?.carType?.id}
                                className="icon icon_car"
                              />
                            </div>
                            <div className="available-drivers__text">
                              {handleCarText(
                                driver?.vehicle?.brand,
                                driver?.vehicle?.model
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="driver__table__third__cell">
                          <span>
                            {driver?.orderCount > 0
                              ? handleNumberwithPadding(driver?.orderCount)
                              : ""}
                          </span>
                        </div>

                        <div className="driver__table__last__cell">
                          {driver?.score <= 0 && (
                            <span
                              style={handleStatusesBadge("rejected")}
                              className="my-auto"
                            >
                              {driver?.score}
                            </span>
                          )}
                          <button
                            className="btn btn_accent customized-details-btn ml-2"
                            type="button"
                            onClick={() => {
                              handleAssignDriverShift(driver);
                            }}
                            disabled={isChangingStatus}
                          >
                            <FaCheck className="btn__icon icon__sm" />
                            Assign
                          </button>
                        </div>
                      </div>
                    )
                )
              ) : (
                <div className="inner__scroll__view__container">
                  No Driver Found
                </div>
              )}
            </div>
          }
        </React.Fragment>
      )}
    </main>
  );
};

export default AssignDriver;

const DriverName = (props) => {
  const { driver, driversOption } = props || {};
  return (
    <div className="available-drivers__inner">
      <div className="available-drivers__icon-holder">
        <ImUser className={`icon`} />
      </div>
      <div className="driver_name_badge">
        <span
          className={`${!checkForIsDriverNew(driver?.orderCount) && "new__driver__badge"}`}
          style={{ marginRight: "10px" }}
        >
          {driver?.firstName} {driver?.lastName}{" "}
        </span>
        {
          driversOption === "interested" && <span className="date">
            {driver?.interestedDate && moment(driver?.interestedDate).format("MM-DD-YYYY hh:mm A")}</span>
        }
      </div>
      {driver?.shiftStatus?.length > 0 && (
        <StatusBadge
          historyArr={driver?.shiftStatus}
          title={driver?.shiftStatus[0]}
        />
      )}
    </div>
  );
};

const StatusBadge = (props) => {
  const { historyArr, title } = props;
  return (
    <span
      className={`${!title.includes("approved") ? "textStyleForStatus" : ""}`}
      style={handleStatusesBadge(title)}
    >
      {removeDash(
        historyArr[0] === "approved"
          ? "Waiting for approval"
          : convertToCapitalize(historyArr[0])
      )}
    </span>
  );
};
