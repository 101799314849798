import { createApi } from "@reduxjs/toolkit/query/react";
import { get, spread, all } from "axios";
import { baseQueryWithAuthInterceptor } from "./util";
var qs = require('qs');

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/orders" }),
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: (body) => {
        const opts = {};
        if (body && body.driverId) {
          opts.params = { driverId: body.driverId };
        }
        if (body && body.status) {
          opts.params = { status: body.status };
        }
        if (body && body.day) {
          opts.params = { day: body.day };
        }
        if (body && body.fromDate && body.toDate) {
          opts.params = { fromDate: body.fromDate, toDate: body.toDate };
        }
        if (body && body.ids) {
          opts.params = { ids: JSON.stringify(body.ids) };
        }
        return {
          url: "",
          ...opts,
        };
      },
      providesTags: ["Order"],
    }),
    getOrder: builder.query({
      query: (id) => id,
      providesTags: ["Order"],
    }),
    updateOrder: builder.mutation({
      query: (body) => ({
        url: body._id,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Order", _id: result._id }],
    }),
    createOrder: builder.mutation({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Order"],
    }),
    changeStatus: builder.mutation({
      query: (body) => ({
        url: `${body._id}/${body.status}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Order", _id: result._id }],
    }),
    removeOrder: builder.mutation({
      query: (body) => ({
        url: "cancel",
        method: "DELETE",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Order"],
    }),
    importOrders: builder.mutation({
      query: (body) => ({
        url: "import",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["Order"],
    }),
    getApprovedOrders: builder.query({
      query: (body) => {
        const opts = {};

        opts.params = { status: "approved" };
        return {
          url: "",
          ...opts,
        };
      },
      providesTags: ["Order"],
    }),
    rejectOrderPayment: builder.mutation({
      query: (body) => ({
        url: `${body._id}/rejectPayment`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Order", _id: result._id }],
    }),
    approveOrderPayment: builder.mutation({
      query: (body) => ({
        url: `${body._id}/approve`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Order", _id: result._id }],
    }),
    getEstimatedPayment: builder.query({
      query: () => "get/estimatedPay",
      providesTags: ["Admin"],
    }),
    swapShift: builder.mutation({
      query: (body) => ({
        url: `${body._id}/swap`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Order", _id: result._id }],
    }),
    InterestedDriver: builder.query({
      query: (body) => {
        return {
          url: `/intrested-count/drivers?fromDate=${body?.id?.fromDate}&toDate=${body?.id?.toDate}&metro=${body?.id?.metro}&status=${body?.id?.status}&hub=${body?.id?.hub}&sort=${body?.id?.sort}`,
          method: "GET",
        };
      },
    }),
    getPodData: builder.query({
      query: (id) => {
        return {
          url: `/pod/${id}`,
          method: "GET",
        };
      },
    }),
    getUnaccountedPackages: builder.query({
      query: (id) => {
        return {
          url: `/${id}/unaccounted-packages`,
          method: "GET",
        };
      },
    }),
    updateBulkOrder: builder.mutation({
      query: (body) => ({
        url: "/bulkUpdate",
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        !error && [{ type: "Order", _id: result._id }],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderQuery,
  useUpdateOrderMutation,
  useCreateOrderMutation,
  useChangeStatusMutation,
  useRemoveOrderMutation,
  useImportOrdersMutation,
  useGetApprovedOrdersQuery,
  useRejectOrderPaymentMutation,
  useApproveOrderPaymentMutation,
  useGetEstimatedPaymentQuery,
  useSwapShiftMutation,
  useInterestedDriverQuery,
  useGetPodDataQuery,
  useGetUnaccountedPackagesQuery,
  useUpdateBulkOrderMutation,
} = ordersApi;

const ERROR_MSG = "Something went wrong";

const handleQuery = (query) => {
  let queryStr = "";
  Object.keys(query).forEach((key, idx) => {
    if (key === "status" && query[key] === "all") return;
    const isFirstQuery = idx < 1 ? "?" : "&";
    const isValue = query[key] ? `${isFirstQuery}${key}=${query[key]}` : "";
    return (queryStr += isValue);
  });
  return queryStr;
};

export const getOrdersList = async (query) => {
  console.log(query, "getOrdersList");
  const { fromDate, toDate, isTotal, page, limit, search, metro, status, hub, isShiftRejectReason, isArriveHub ,routeCode } = query
  const queryStr = `/api/orders?fromDate=${fromDate}&toDate=${toDate}&page=${page}&limit=${limit}&isTotal=${isTotal}&search=${search}&isRejectReason=${isShiftRejectReason}&isDriverArrived=${isArriveHub}&routecode=${routeCode || ""}`
  const getShifts = await get(queryStr, {
    'params': { 'metro': metro, "status": status, "hub": hub },
    'paramsSerializer': function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  });
  try {
    const { status, data } = getShifts || {};
    if (status === 200 && data) return { shiftsData: data };
    else return ERROR_MSG;
  } catch (error) {
    return error?.response?.data || ERROR_MSG;
  }
};

export const getOrdersCount = async (query) => {
  const { fromDate, toDate, search, metro, status, hub, isArriveHub,routeCode} = query
  const queryStr = `/api/shifts/count?fromDate=${fromDate}&toDate=${toDate}&search=${search}&isDriverArrived=${isArriveHub}&routecode=${routeCode || ""}`
  const shiftsCountData = await get(queryStr, {
    'params': { 'metro': metro, "status": status, "hub": hub },
    'paramsSerializer': function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  });
  try {
    const { status, data } = shiftsCountData || {};
    if (status === 200 && data?.result) return { shiftCountData: data?.result };
    else return ERROR_MSG;
  } catch (error) {
    return error?.response?.data || ERROR_MSG;
  }
};
