import React, { useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  Modal,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";

import { FaCheck } from "react-icons/fa";
import { ErrorMessage } from "../../../shared/Form";
import { MdCancel } from "react-icons/md";

import {
  useApprovePaymentMutation,
  useGetPayeesQuery,
} from "../../../../api/drivers";
import { actions } from "../../../../redux/store/store";

import "../style.scss";
import "./style.adhocPayment.scss";
import "../massPayments/style.massPayments.scss";
import { Box } from "@mui/material";
import { useSendSlackMessageMutation } from "../../../../api/slackMessage";
import { ADHOC } from "../../../../constants/variables";

const requiredMessage = "This field is required";
const validationSchema = Yup.object().shape({
  from: Yup.string().required(requiredMessage),
  amount: Yup.number().required(requiredMessage).positive(),
});

const CustomModal = () => {
  const title = `New ${ADHOC}`;

  const [startPay, setStartPay] = useState(false);

  const { data: availableDrivers, isFetching: isLoading } = useGetPayeesQuery();

  const [approvePayment] = useApprovePaymentMutation();
  const [sendSlackMessage] = useSendSlackMessageMutation();

  const [statuses, setStatuses] = useState({});
  const [username, setUsername] = useState("");
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [driverList, setDriverList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const currentUser = useSelector((state) => state.auth.currentUser);

  const form = useForm({
    defaultValues: {
      amount: "",
      note: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onCancel = useCallback(() => {
    if (
      !form.formState.isDirty ||
      window.confirm("Do you want to close the dialog? Changes will be lost.")
    ) {
      actions.modal.closeAdhocpayment();
    }
  }, [form.formState.isDirty]);

  const handleAddDriver = (e) => {
    let arr = username.split("::");

    if (username && amount) {
      for (let i = 0; i < driverList.length; i++) {
        const element = driverList[i];
        if (element.username === arr[0]) return;
      }

      setDriverList([
        ...driverList,
        { id: arr[1], username: arr[0], amount: amount, note: note },
      ]);
      setTotalAmount((total) => total + Number(amount));
      setAmount("");
      setUsername("");
      setNote("");
      form.setValue("from", null);
    }
  };

  const handleRemoveDriver = (idx) => {
    const temp = [...driverList];

    for (let i = 0; i < driverList.length; i++) {
      const element = driverList[i];
      if (element.id === idx) {
        setTotalAmount((total) => (total -= element.amount));
        break;
      }
    }

    // removing the element using splice
    temp.splice(idx, 1);

    // updating the list
    setDriverList(temp);
  };

  const handleSubmit = async () => {
    setStartPay(true);

    for (let i = 0; i < driverList.length; i++) {
      let result;
      const element = driverList[i];
      setStatuses((statuses) => ({ ...statuses, [element.id]: "processing" }));

      let arr = element.username.split("-");
      let uname = `${arr[0]} - ${arr[1]}`;
      result = await approvePayment({
        _id: element.id,
        payeeName: uname,
        amount: element.amount,
        note: element.note,
      });

      setStatuses((statuses) => ({
        ...statuses,
        [element.id]: result.data.transactionStatus,
      }));
    }

    if (currentUser && currentUser.role === "admin") {
      await sendSlackMessage({
        type: "adhocpayment",
      });
    }
  };

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className={`modal ${isLoading ? "when-loading" : "after-loading"}`}
        style={{ maxWidth: "60.875rem" }}
      >
        <div className="modal__header">
          <h2 className="modal__title">{title}</h2>
        </div>
        <div className="modal__body">
          {!isLoading ? (
            <form style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <fieldset
                    style={{
                      width: "90%",
                      margin: "1rem 0",
                      display: "flex",
                      alignItems: "center",
                      padding: "0.5rem 0.5rem 0.5rem 0.5rem ",
                    }}
                  >
                    <label style={{ paddingRight: "10px" }}>
                      <b>Payees</b>
                    </label>
                    <Controller
                      control={form.control}
                      name="from"
                      render={({
                        // field: { onChange, value, ref },
                        fieldState: { error },
                      }) => (
                        <React.Fragment>
                          <Autocomplete
                            size="small"
                            style={{ width: "100%" }}
                            options={availableDrivers}
                            id="from"
                            value={
                              availableDrivers && availableDrivers.fullName
                            }
                            getOptionLabel={(option) => option.fullName}
                            onChange={(e, newValue) => {
                              if (newValue)
                                setUsername(
                                  `${newValue.fullName}::${newValue._id}`
                                );
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {option.fullName}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Payee"
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                          <ErrorMessage error={error} />
                        </React.Fragment>
                      )}
                    />
                  </fieldset>

                  <fieldset
                     style={{
                      width: "45%",
                      margin: "1rem 0",
                      display: "flex",
                      alignItems: "center",
                      padding: "8px"
                    }}
                  >
                    <label style={{ width: "90%" }}>
                      <b>Amount ($)</b>
                    </label>

                    <input
                      type="number"
                      name="amount"
                      className="form__input"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      style={{ width: "80%", height: "42px" }}
                      min="1"
                    />
                  </fieldset>
                </div>

                <div style={{ width: "70%" }}>
                  <fieldset
                    style={{
                      padding: "0.5rem 0.5rem 0.5rem 0.5rem ",
                      margin: "1rem 0",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label style={{ paddingRight: "30px" }}>
                      <b>Note</b>
                    </label>
                    <input
                      type="text"
                      name="note"
                      className="form__input"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      style={{ width: "100%", height: "40px" }}
                      placeholder="Enter Note Here..."
                    />
                  </fieldset>

                  <fieldset
                    style={{
                      width: "30%",
                      padding: "8px"
                    }}
                  >
                    <button
                      className="btn"
                      name="addDriver"
                      id="addDriver"
                      disabled={startPay ? true : null}
                      style={{ width: "100%", height: "1rem" }}
                      onClick={(e) => {
                        handleAddDriver(e);
                      }}
                      type="button"
                    >
                      +
                    </button>
                  </fieldset>
                </div>
              </div>

              <table className="table table_striped table_thead-sticky mass-payments__table">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "50%" }}>Driver</th>
                    <th style={{ width: "5%" }}>Amount</th>
                    <th style={{ width: "30%" }}>Note</th>
                    <th style={{ width: "5%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {driverList.length > 0 &&
                    driverList.map((user, index) => (
                      <tr key={index}>
                        <td className="mass-payments__table-td-status">
                          {/*{startPay &&*/}
                          <div className="mass-payments__status-holder">
                            <div className="mass-payments__status-icon-holder">
                              {(() => {
                                switch (statuses[user.id]) {
                                  case "pending":
                                  case "paid":
                                  case "processed":
                                  case "approved":
                                    return (
                                      <FaCheck
                                        style={{ color: "green" }}
                                        className="mass-payments__status-icon mass-payments__status-icon_payed"
                                      />
                                    );
                                  case "processing":
                                    return (
                                      <React.Fragment>
                                        <CircularProgress
                                          size={20}
                                          className="mass-payments__status-icon mass-payments__status-icon_pending"
                                        />
                                      </React.Fragment>
                                    );
                                  case "rejected":
                                    return (
                                      <MdCancel
                                        style={{ color: "red" }}
                                        className="mass-payments__status-icon mass-payments__status-icon_rejected"
                                      />
                                    );
                                  default:
                                    return <React.Fragment></React.Fragment>;
                                }
                              })()}
                            </div>
                          </div>
                          {/*}*/}
                        </td>

                        <td className="mass-payments__table-td-amount">
                          {user.username}
                        </td>
                        <td className="mass-payments__table-td-amount">
                          {user.amount && "$" + Number(user.amount).toFixed(2)}
                        </td>
                        <td className="mass-payments__table-td-amount">
                          {user.note}
                        </td>
                        <td className="mass-payments__table-td-amount">
                          <button
                            className="closebtn"
                            name="RemoveDriver"
                            id="RemoveDriver"
                            disabled={startPay ? true : null}
                            onClick={(e) => {
                              handleRemoveDriver(user.id);
                            }}
                            type="button"
                          >
                            X
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {driverList.length > 0 && (
                  <tfoot>
                    <tr>
                      <th></th>
                      <th>Total Amount</th>
                      <th colSpan="3">
                        $
                        {totalAmount && typeof totalAmount === "number"
                          ? totalAmount.toFixed(2)
                          : "00"}
                      </th>
                    </tr>
                  </tfoot>
                )}
              </table>

              {/* table end */}
            </form>
          ) : (
            <div className="loading-wrapper">
              <CircularProgress />
            </div>
          )}
        </div>
        <div className="modal__footer">
          <button
            className="btn btn_accent"
            disabled={startPay || driverList.length <= 0 ? true : null}
            onClick={() => {
              handleSubmit();
            }}
          >
            Pay all Drivers
          </button>

          <button
            className="btn"
            onClick={() => actions.modal.closeAdhocpayment()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
