import React, { useState, useEffect, useLayoutEffect } from "react";
import { CircularProgress } from "@mui/material";
import moment from "moment";

import { useGetOrdersQuery } from "../../../../api/orders";
import {
  convertToCapitalize,
  removeDash,
} from "../../../../constants/extras/convertToCapitalize";
import { handleEstimatedShiftPay } from "../../../../constants/extras/handleShiftPayAndTime";
import { handleStatusesBadge } from "../../../../constants/extras/statusesBadge";
import Table from "../../Table";
import "../style.scss";
import "./style.driver.scss";
import { cancelScore, cancelType } from "../../../../constants/extras/rewording";

const History = ({ id }) => {
  const { data: driverOrders, isFetching } = useGetOrdersQuery(
    {
      driverId: id,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [historyArr, setHistoryArr] = useState([]);
  const [arrWithStatusAndCount, setArrWithStatusAndCount] = useState([
    { status: "assigned", count: 0 },
    { status: "rejected", count: 0 },
    { status: "delivery_started", count: 0 },
    { status: "complete", count: 0 },
    { status: "approved", count: 0 },
    { status: "paid", count: 0 },
    { status: "cancelled", count: 0 },
  ]);

  useLayoutEffect(() => {
    if (!driverOrders) return;
    setHistoryArr(driverOrders);
  }, [driverOrders]);

  useEffect(() => {
    if (!historyArr) return;
    let obj = {}
    for (let i = 0; i < historyArr.length; i++) {
      const status = historyArr[i].status;
      obj[status] ? obj[status]++ : obj[status] = 1
      
    }
    setArrWithStatusAndCount([
      { status: "assigned", count: obj['assigned'] },
      { status: "rejected", count: obj['rejected'] },
      { status: "delivery_started", count: obj['delivery_started'] },
      { status: "complete", count: obj['complete'] },
      { status: "approved", count: obj['approved'] },
      { status: "paid", count: obj['paid'] },
      { status: "cancelled", count: obj['cancelled'] },
    ])
    
  }, [historyArr]);

  const columns = [
    {
      name: "status",
      className: "table__td_centered align-center w-auto font__sm py-2",
      label: "Status",
      sortable: true,
      renderer: (item) => (
        <React.Fragment>
          <div className="status-in-table">
            <div className="status-in-table__text">
              <span style={handleStatusesBadge(item.status)}>
                {removeDash(
                  item?.status === "approved"
                    ? "Waiting for approval"
                    : convertToCapitalize(item?.status)
                )}
              </span>
            </div>
          </div>
        </React.Fragment>
      ),
    },
    {
      name: "cancelType",
      className: "align-center w-20 font__sm",
      label: "Cancellation Reason",
      sortable: true,
      renderer: (item) => (
        <React.Fragment>
          <div>
            {
              item?.cancelReason ? (
                <>
                  <div>
                    {item?.cancelType && <b>{cancelType(item?.cancelType)} : </b>} {item?.cancelReason}
                  </div>
                </>
              ) : (" - ")
            }
          </div>
        </React.Fragment>
      ),
    },
    {
      name: "score",
      className: "table__td_centered align-center w-auto font__sm py-2",
      label: "Score",
      sortable: true,
      renderer: (item) => (
        <React.Fragment>
          <div>
            {
              item?.cancelReason || item?.shiftStartedLate ? (
                <>
                  <span
                    style={handleStatusesBadge("rejected")}
                    className="my-auto"
                  >
                    {item?.cancelType ? cancelScore(item?.cancelType) : (item?.shiftStartedLate && -0.5)}
                  </span>
                </>
              ) : (" - ")
            }
          </div>
        </React.Fragment>
      ),
    },
    {
      name: "dayDeliveryStart",
      className: "table__td_nowrap font__sm",
      label: "Route Date",
      sortable: true,
      renderer: (item) =>
        item.dayDeliveryStart &&
        moment(item.dayDeliveryStart).utc().format("MM-DD-YYYY"),
    },
    {
      name: "routeCode",
      label: "Route Code",
      className: "font__sm",
      sortable: true,
    },
    {
      name: "metro",
      label: "Metro",
      className: "table__td_nowrap font__sm",
      sortable: true,
    },
    {
      name: "from-name",
      label: "Starting Delivery Station",
      className: "table__td_nowrap font__sm",
      sortable: true,
      renderer: (item) => {
        return item?.from && item?.from?.name;
      },
    },
    {
      name: "price",
      label: "Payout",
      sortable: true,
      className: "font__sm",
      renderer: (item) =>
        `${(item && typeof item.totalPrice === "number" && item?.status === "paid")
          ? "$".concat(item.totalPrice.toFixed(2))
          : (item && typeof item.price === "number" ? "$".concat(item.price.toFixed(2)) : "-")
        }`,
    },
    {
      name: "note",
      label: "Notes",
      sortable: true,
      className: "align-center font__sm",
    },
    {
      name: "internalNote",
      label: "Internal Notes",
      sortable: true,
      className: "table__td_nowrap font__sm",
    },
  ];

  return (
    <div className="w-100">
      <div className="card">
        <div className="card-header header__helper d-flex align-items-center w-100">
          <h6
            className="card-title w-10"
            style={{ fontWeight: "600", whiteSpace: "nowrap" }}
          >
            History
          </h6>

          <div className="status__container">
            {historyArr[0]?.driver?.score < 0 && (
              <StatusBadge historyArr={historyArr} title="score" />
            )}
            {historyArr[0]?.driver?.rejectShiftCount > 0 && (
              <StatusBadge historyArr={historyArr} title="rejected" />
            )}
            {arrWithStatusAndCount?.map((data, idx) => {
              if (typeof data !== "object" || data?.count === 0) return null;
              return (
                data?.count > 0 && (
                  <div
                    key={`${(idx + Math.random()).toString()}`}
                    className={`w-auto ${data?.count > 0 ? "mb__sm" : ""}`}
                  >
                    {typeof data === "object" && data?.count > 0 && (
                      <span
                        style={handleStatusesBadge(data?.status)}
                        className={`mb-2`}
                      >
                        {removeDash(
                          data?.status === "approved"
                            ? "Waiting for approval"
                            : convertToCapitalize(data?.status)
                        )}
                        :{" "}
                        {data?.count > 0 && data?.count < 10
                          ? "0".concat(data?.count)
                          : data?.count}
                      </span>
                    )}
                  </div>
                )
              );
            })}
          </div>
        </div>
        {isFetching ? (
          <div
            className="inner__scroll__view__container"
            style={{ height: "8rem" }}
          >
            <CircularProgress />
          </div>
        ) : (
          <React.Fragment>
            {driverOrders &&
              !driverOrders?.error &&
              driverOrders?.length > 0 ? (
              <div
                style={{
                  height: driverOrders?.length > 10 ? "50vh" : "auto",
                  overflow: "auto",
                }}
              >
                <Table
                  columns={columns}
                  items={driverOrders && driverOrders}
                  width={"140%"}
                />
              </div>
            ) : (
              <div
                className="d-flex w-100 justify-content-center align-items-center"
                style={{ height: "10vh", width: "auto" }}
              >
                <b> No History Found!</b>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default History;

const StatusBadge = (props) => {
  const { historyArr, title } = props;
  return (
    <div
      className={`w-auto py-1 ${historyArr[0]?.driver?.rejectShiftCount > 0 ||
        historyArr[0]?.driver?.score <= 0
        ? "mb__sm"
        : ""
        }`}
    >
      {(title === "rejected"
        ? historyArr[0]?.driver?.rejectShiftCount > 0
        : historyArr[0]?.driver?.score) && (
          <span
            style={{
              ...handleStatusesBadge("rejected"),
              display: "inline-block",
            }}
          >
            {title === "rejected" ? (
              <>
                {convertToCapitalize(title)}:{" "}
                {historyArr[0]?.driver?.rejectShiftCount < 10
                  ? "0".concat(historyArr[0]?.driver?.rejectShiftCount)
                  : historyArr[0]?.driver?.rejectShiftCount}{" "}
              </>
            ) : (
              <>
                {convertToCapitalize(title)} {": "} {historyArr[0]?.driver?.score}
              </>
            )}
          </span>
        )}
    </div>
  );
};
