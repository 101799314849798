import React, { useCallback } from 'react'
import { Modal } from '@mui/material';
import { actions } from '../../../../redux/store/store';
import Table from '../../Table';
import moment from 'moment';
import { handleCurrency } from '../../../../constants/extras/handleNumbers';
import { useNavigate } from 'react-router-dom';
import { handleStatusesBadge } from '../../../../constants/extras/statusesBadge';

function PaymentFailedDetailModal(props) {
  const navigate = useNavigate()
  const { id } = props;

  const onCancel = useCallback(() => {
    actions.modal.closeFailedPayment();
  }, []);

  const columns = [
    {
      name: "dayDeliveryStart",
      label: "Route Date",
      maxWidth: "90px",
      sortable: true,
      renderer: ({shiftId}) =>
        shiftId?.dayDeliveryStart &&
        moment(shiftId?.dayDeliveryStart).utc().format("MM-DD-YYYY"),
    },
    {
      name: "driver-firstName",
      label: "Driver Name",
      maxWidth: "180px",
      sortable: true,
      renderer: ({ shiftId }) =>
        shiftId?.driver[0]?.username && (
          <span >
            {`${shiftId?.driver[0]?.firstName || ""} ${shiftId?.driver[0]?.lastName || ""}`}
          </span>
        ),
    },
    {
      name: "metro",
      label: "Metro",
      maxWidth: "150px",
      sortable: true,
      renderer: ({shiftId}) => shiftId?.metro ? shiftId?.metro : "-"
    },
    {
      name: "price",
      label: "Payout",
      maxWidth: "70px",
      sortable: true,
      className: "align-right",
      renderer: ({ shiftId }) => (shiftId?.price ? `$${handleCurrency(shiftId?.price)}` : ""),
    },
    {
      name: "bonusPrice",
      label: "Bonus",
      maxWidth: "50px",
      sortable: true,
      className: "align-right",
      renderer: ({ shiftId }) => (shiftId?.bonusPrice ? `$${handleCurrency(shiftId?.bonusPrice)}` : ""),
    },
    {
      name: "totalPrice",
      label: "Total Payout",
      maxWidth: "70px",
      sortable: true,
      className: "align-right",
      renderer: ({ shiftId }) => (shiftId?.totalPrice ? `$${handleCurrency(shiftId?.totalPrice)}` : ""),
    },
    {
      name: "transactionFailedReason",
      label: "Failed Reason",
      sortable: true,
      renderer: (item) => item?.transactionFailedReason
    },
 {
      name: "status",
      label: "Status",
      sortable: true,
      renderer: (item) => (
        <div className="status-in-table">
            <div className="status-in-table__text" style={{margin: '5px 0'}}>
            <span style={handleStatusesBadge(item?.shiftId?.status==='paid' ? 'paid' : 'failed')}>
                    {item?.shiftId?.status==='paid' ? 'paid' : 'failed'}
                  </span>
            </div>
        </div>
      )
    }
  ];

  const handleFailedPaymentOnce = () => {
    window.open(`/payments?isPaymentFail`, '_blank')
    onCancel()
  }

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className={`modal after-loading`}
        style={{ maxWidth: "90rem" }}
      >
        <div className="modal__header">
          <h2 className="modal__title">Failed Payment Details</h2>
        </div>
        <div className="modal__body">

          {id?.shiftPayments &&
            id?.shiftPayments?.length > 0 ? (
            <div className="card" style={{ border: "1px solid #DADADA" }}>
              <div
                style={{
                  height: "70vh",
                  overflow: "auto",
                }}
              >
                <Table
                  columns={columns}
                  items={id && id?.shiftPayments && id?.shiftPayments?.filter(e => !e?.isPaid)}
                />
              </div>
            </div>
          ) : (
            <div
              className="inner__scroll__view__container"
              style={{ height: "100%" }}
            >
              No result found
            </div>
          )}
        </div>
        <div className="modal__footer">
          <button
            className="btn"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="btn btn_accent"
            onClick={handleFailedPaymentOnce}
          >
            Failed payment at once
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default PaymentFailedDetailModal
