import React, { useCallback } from 'react'
import { CircularProgress, Modal } from '@mui/material';
import { actions } from '../../../../redux/store/store';
import Table from '../../Table';
import { useInterestedDriverQuery } from '../../../../api/orders';
import { useEffect } from 'react';
import { first } from 'lodash';
import { getRoute } from '../../../../util/time';

function InterestedDriverModal(props) {
  const { id } = props;
  const { data, isLoading, refetch } = useInterestedDriverQuery({ id })

  const onCancel = useCallback(() => {
    actions.modal.closeInterestedDriver();
  }, []);

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  const columns = [
    {
      name: "firstName",
      label: "Driver Name",
      maxWidth: "50px",
      sortable: true,
      renderer: (item) => (
        <span>{`${item?.firstName || ''} ${item?.lastName || ''}`}</span>
      ),
    },
    {
      name: "metro",
      label: "Metro",
      maxWidth: "50px",
      sortable: true,
      renderer: ({ metro }) => <span>{metro || '-'}</span>
    },
    {
      name: "startTime",
      label: "Start Time",
      maxWidth: "75px",
      sortable: true,
      renderer: ({ startTime }) => {
        const sortedStartTime = startTime ? [...startTime].sort() : [];
        const formattedStartTime = sortedStartTime.map((e) => getRoute(e)).join(' , ');
        return <span>{formattedStartTime || '-'}</span>;
      },
    }
  ];

  return (
    <Modal
      open
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div
        className={`modal after-loading`}
        style={{ maxWidth: "70rem" }}>
        <div className="modal__header">
          <h2 className="modal__title">{id?.sort ? 'Sort Interested Driver Details' : 'Interested Driver Details'}</h2>
        </div>
        <div className="modal__body">
          {!isLoading ? (<div className="card" style={{ border: "1px solid #DADADA" }}>
            <div
              style={{
                height: "60vh",
                overflow: "auto",
              }}>
              <Table
                columns={columns}
                items={data?.result} />
            </div>
          </div>) : (<div
            className="inner__scroll__view__container"
            style={{ height: "100%" }}
          >
            <CircularProgress className="loading-progress" />
          </div>)}
        </div>
        <div className="modal__footer">
          <button onClick={onCancel}
            className="btn">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default InterestedDriverModal
