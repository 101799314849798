import { createSlice } from "@reduxjs/toolkit";

export const metroSlice = createSlice({
  name: "metro",
  initialState: {
    value: undefined,
  },
  reducers: {
    selectMetro: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { selectMetro } = metroSlice.actions;
export default metroSlice.reducer;
