
export const maskingInfo =  (mainStr) => {
    if(!mainStr)
     return;
    let vis = mainStr.slice(-4),
    countNum = '';
  
    for(var i = (mainStr.length)-4; i>0; i--){
        countNum += '*';
    }
    return (countNum+vis)
}

export const containsEmptyString = arr => arr.some(str => typeof str === 'string' && str.trim() === '');