import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";

export const paymentCalendarApi = createApi({
  reducerPath: "paymentCalendarApi",
  baseQuery: baseQueryWithAuthInterceptor({ baseUrl: "/api/" }),
  tagTypes: ["paymentCalendar"],
  endpoints: (builder) => ({
    getAllPaymentCalendar: builder.query({
      query: () => {
        return {
          url: "paymentCalendars",
          method: "GET",
        };
      },
      providesTags: ["paymentCalendar"],
    }),
    getPaymentCalendarById: builder.query({
      query: (id) => {
        return {
          url: `paymentCalendars/${id._id}`,
          method: "GET",
        };
      },
      providesTags: ["paymentCalendar"],
    }),
    createPaymentCalendar: builder.mutation({
      query: (body) => ({
        url: "paymentCalendar",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => !error && ["paymentCalendar"],
    }),
    updatePaymentCalendar: builder.mutation({
      query: ({ data, id }) => ({
        url: `paymentCalendar/${id?._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => !error && ["paymentCalendar"],
    }),
    deletePaymentCalendar: builder.mutation({
      query: (id) => ({
        url: `paymentCalendar/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => !error && ["paymentCalendar"],
    }),

  }),
});

export const {
  useCreatePaymentCalendarMutation,
  useGetAllPaymentCalendarQuery,
  useGetPaymentCalendarByIdQuery,
  useUpdatePaymentCalendarMutation,
  useDeletePaymentCalendarMutation
} = paymentCalendarApi

