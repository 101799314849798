import _ from "lodash";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, Select, MenuItem, CircularProgress, OutlinedInput } from "@mui/material";
import { RiFileTransferFill } from "react-icons/ri";

import MetroSelect from "../../../shared/metroSelect";
import { InputItem, ErrorMessage } from "../../../shared/Form";
import {
  useGetShiftTemplateQuery,
  useUpdateShiftTemplateMutation,
  useCreateShiftTemplateMutation,
  useChangeStatusMutation,
} from "../../../../api/shiftTemplate";
import { useGetStoresQuery } from "../../../../api/stores";
import { Times } from "../../../../util/time";
import { actions } from "../../../../redux/store/store";
import ConfirmDialogModal from "../../../shared/customModal/confirmDialog";
import Timepicker from "../../../shared/Timepicker";
import Hourpicker from "../../../shared/Hourpicker";
import "../style.scss";
import "./style.shiftTemplate.scss";
import { hoverAndFocusedStyle, hubSelectStyle } from "../../orderHelper/styles";
import {
  handleCurrency,
  handleNumberwithPadding,
} from "../../../../constants/extras/handleNumbers";
import { sendSuccessMessage } from "../../../../constants/extras/sendSuccessMsg";
import { checkForError } from "../../../../constants/extras/errorHandlers";
import { API_SUCCESS } from "../../../../constants/variables/successMessage";
import { MSG } from "../../../../constants/variables/infoMsg";
import { shiftTemplateValidationSchema } from "../../../../constants/validaitonSchema";
import { checkArrForMapping } from "../../../../constants/validaitonSchema/validation.ts";
import { toggleTime } from "../../../../constants/variables/dates";
import { makeStyles } from "@material-ui/core/styles";

const { CREATED, UPDATED } = API_SUCCESS.SHIFT_TEMPLATE;
const progressStyle = { width: "1rem", height: "1rem", color: "#ffffff" };
const arrOfOneToFive = [...Array(5).keys()];

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    "& $notchedOutline": {
      borderColor: "#f61818"
    },
    "&:hover $notchedOutline": {
      borderColor: "#f61818"
    },
    "&$focused $notchedOutline": {
      borderColor: "#f61818"
    }
  },
  focused: {},
  notchedOutline: {}
}));

const ShiftTemplateModal = ({ id }) => {
  const title = id ? `Shift Template #${id} details` : "New Shift Template";
  const outlinedInputClasses = useOutlinedInputStyles();  

  const [isLoaded, setIsLoaded] = useState(false);
  const [showDialog, setShowDialog] = useState(null);
  const [generalError, setGeneralError] = useState(null);
  const [filteredStores, setFilteredStores] = useState([]);

  const { data: shiftTemplate, status: orderLoadingStatus } =
    useGetShiftTemplateQuery(id, {
      refetchOnMountOrArgChange: true,
      skip: !id,
    });
  const { data: stores, status: storesLoadingStatus } = useGetStoresQuery(
    null,
    { refetchOnMountOrArgChange: true }
  );

  const [update, { error: updateError, isLoading: isUpdating }] =
    useUpdateShiftTemplateMutation();
  const [create, { error: createError, isLoading: isCreating }] =
    useCreateShiftTemplateMutation();
  const [changeStatus] = useChangeStatusMutation();

  const selectedMetro = useSelector((state) => state.metro.value);

  const [allAvailableTime] = useState(arrOfOneToFive);
  const currentUser = useSelector((state) => state.auth.currentUser);

  const form = useForm({
    defaultValues: {
      metro: selectedMetro || "",
      availableTime: [0, 1, 2, 3, 4],
      routeCode: "",
      numberOfShifts: "",
      title: "",
      maxTaskPerDriver: 100,
    },
    resolver: yupResolver(shiftTemplateValidationSchema),
  });

  const { price, maxTaskPerDriver, from } = shiftTemplate || {};
  const { setValue, getValues, formState, watch, handleSubmit, control } = form;

  const metro = watch("metro");

  useEffect(() => {
    setIsLoaded(
      ["uninitialized", "fulfilled"].includes(orderLoadingStatus) &&
        storesLoadingStatus === "fulfilled"
    );
  }, [orderLoadingStatus, storesLoadingStatus]);

  useEffect(() => {
    const error = updateError || createError;
    setGeneralError(
      error
        ? {
            message:
              error.message ||
              error.error ||
              (error.data && error.data.message) ||
              error.data,
          }
        : null
    );
  }, [updateError, createError]);

  useEffect(() => {
    if (!shiftTemplate) return;
    Object.entries(shiftTemplate).forEach(([name, value]) =>
      setValue(name, value)
    );
    setValue("availableTime", arrOfOneToFive);
    setValue("from", from?._id || "");
    setValue("price", handleCurrency(price));
    setValue("maxTaskPerDriver", handleNumberwithPadding(maxTaskPerDriver));
  }, [shiftTemplate, setValue, from, price, maxTaskPerDriver]);

  useEffect(() => {
    if (!stores) return;
    const { metro, from } = getValues() || {};
    const availableStores = metro
      ? stores?.filter((store) => store.metro === metro)
      : stores;
    setFilteredStores(availableStores);
    if (!availableStores?.find(({ _id }) => _id === from))
      setValue("from", null);
  }, [metro, stores, getValues, setValue]);

  const updateEstimatedShiftTimeFrom = (val) =>
    setValue("estimatedShiftTimeFrom", val);

  const updateEstimatedShiftTimeTo = (val) =>
    setValue("estimatedShiftTimeTo", val);

  const onSubmit = async (data) => {
    const { data: result } = await (id ? update(data) : create(data));

    if (!result.error) sendSuccessMessage(id ? UPDATED : CREATED);
    else checkForError(result);
    actions.modal.closeShiftTemplate();
  };

  const onCancel = useCallback(() => {
    if (!formState.isDirty || window.confirm(MSG.MODAL_CLOSE))
      actions.modal.closeShiftTemplate();
  }, [formState.isDirty]);

  const testDriverName = "Test Driver"; // variable for demonstration
  const DialogTitle = useMemo(
    () => (
      <React.Fragment>
        <RiFileTransferFill className="icon" />
        <span>
          Transfering <strong>{"$" + handleCurrency(price)}</strong> to{" "}
          <strong>{testDriverName}</strong>
        </span>
      </React.Fragment>
    ),
    [price]
  );

  const updateStartTime = (val) => form.setValue("startTime", val);

  const handlePrice = (e) => {
    let t = e.target.value;
    e.target.value =
      t.indexOf(".") >= 0
        ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
        : t;
  };

  let oneToHundread = Array.from({ length: 100 }, (_, i) => i + 1);

  const isSending = useMemo(() => {
    return (
      (isUpdating || isCreating) && (
        
          <CircularProgress size={18}
                sx={{
                  color: '#fff',
                  position: 'absolute',
                  top: '50%',
                  left: '83%',
                  marginTop: '-8px',
                  marginLeft: '-10px',
                }} />
        
      )
    );
  }, [isUpdating, isCreating]);

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={`modal ${isLoaded ? "after-loading" : "when-loading"}`}>
        {showDialog && (
          <ConfirmDialogModal
            title={<DialogTitle />}
            bodyText="Are you sure?"
            isOpen
            onClose={() => setShowDialog(null)}
            confirmAction={() => {
              setShowDialog(null);
              changeStatus({ status: "pay", _id: shiftTemplate._id });
            }}
          />
        )}

        <div className="modal__header">
          <h2 className="modal__title">{title}</h2>
        </div>
        <div className="modal__body">
          {isLoaded ? (
            <React.Fragment>
              <form>
                <div className="form__row">
                  <div
                    className="form__col form__col_height-flex"
                    style={{ width: "48%" }}
                  >
                    <fieldset style={{ width: "100%" }}>
                      <InputItem
                        style={{ display: "grid" }}
                        title="Title"
                        name="title"
                        form={form}
                        required
                      />

                      <div className="form__item" style={{ width: "100%" }}>
                        <label className="form__label">Metro</label>
                        <Controller
                          control={control}
                          name="metro"
                          render={({
                            field: { onChange, value, ref },
                            fieldState: { error },
                          }) => (
                            <React.Fragment>
                              <MetroSelect
                                value={value}
                                onChange={(e, data) => onChange(data)}
                                ref={ref}
                                isError={error && true}
                                style={{ width: "100%" }}
                              />
                              <ErrorMessage error={error} />
                            </React.Fragment>
                          )}
                        />
                      </div>
                      <div className="form__item" style={{ width: "100%" }}>
                        <div>
                          <label className="form__label">Hub</label>
                        </div>

                        <Controller
                          control={control}
                          name="from"
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <React.Fragment>
                              <Select
                                sx={{
                                  ...hubSelectStyle,
                                  ...hoverAndFocusedStyle,
                                }}
                                input={
                                  <OutlinedInput
                                    id="outlined-age-simple"
                                    classes={error && outlinedInputClasses}
                                  />
                                }
                                id="from"
                                value={value || ""}
                                onChange={(e) => onChange(e.target.value)}
                                className={error && "MuiSelect-root-invalid"}
                                style={{ width: "100%"}}
                              >
                                <MenuItem key={0} value={""}>
                                  [no store]
                                </MenuItem>
                                {checkArrForMapping(filteredStores) &&
                                  filteredStores?.map(({ _id, name }) => (
                                    <MenuItem key={_id} value={_id}>
                                      {name}
                                    </MenuItem>
                                  ))}
                              </Select>
                              <ErrorMessage error={error} />
                            </React.Fragment>
                          )}
                        />
                      </div>

                      <div className="form__item" style={{ width: "100%" }}>
                        <label className="form__label">No Of Shifts</label>
                        <Controller
                          control={control}
                          name="numberOfShifts"
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <React.Fragment>
                              <Select
                                className
                                style={{ width: "100%" }}
                                bluronselect
                                id="numberOfShifts"
                                value={value || ""}
                                onChange={(e) => onChange(e.target.value)}
                                sx={{
                                  ...hubSelectStyle,
                                  ...hoverAndFocusedStyle,
                                }}
                                input={
                                  <OutlinedInput
                                    id="outlined-age-simple"
                                    classes={error && outlinedInputClasses}
                                  />
                                }
                              >
                                {oneToHundread.map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              <ErrorMessage error={error} />
                            </React.Fragment>
                          )}
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div style={{ width: "52%" }}>
                    <fieldset>
                      <div>
                        <InputItem
                          title="Route Code Prefix"
                          name="routeCode"
                          form={form}
                          required
                          pattern="[a-zA-Z0-9]+"
                        />
                      </div>

                      <div className="d-flex" style={{ gap: "10.5%" }}>
                        <label className="form__label">
                          Estimated Shift Duration:
                        </label>

                        <label className="form__label">Start Time:</label>
                      </div>

                      <div className="d-flex" style={{ gap: "8%" }}>
                        <div style={{ display: "flex" }}>
                          <div>
                            <Controller
                              control={control}
                              name="estimatedShiftTimeFrom"
                              render={({
                                field: { onChange, value, ref },
                                fieldState: { error },
                              }) => (
                                <Hourpicker
                                  value={value}
                                  updateTime={updateEstimatedShiftTimeFrom}
                                  error={error}
                                />
                              )}
                            />
                          </div>
                          <div
                            className="form__label"
                            style={{ padding: "20px" }}
                          >
                            To
                          </div>
                          <div>
                            <Controller
                              control={control}
                              name="estimatedShiftTimeTo"
                              render={({
                                field: { onChange, value, ref },
                                fieldState: { error },
                              }) => (
                                <Hourpicker
                                  value={value}
                                  updateTime={updateEstimatedShiftTimeTo}
                                  error={error}
                                />
                              )}
                            />
                          </div>
                        </div>

                        <div>
                          <Controller
                            control={control}
                            name="startTime"
                            render={({
                              field: { onChange, value, ref },
                              fieldState: { error },
                            }) => (
                              <Timepicker
                                value={value}
                                updateTravelTime={updateStartTime}
                                error={error}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="d-flex" style={{ gap: "12%" }}>
                        <label className="form__label">
                          Estimated Shift Pay ($):
                        </label>
                        <label className="form__label">
                          Finalized Payment Amount ($):
                        </label>
                      </div>

                      <div className="d-flex w-100" style={{ gap: "8%" }}>
                        <div
                          className="d-flex align-items-center"
                          style={{
                            width: "40%",
                          }}
                        >
                          <InputItem
                            title=""
                            name="estimatedShiftPayFrom"
                            form={form}
                            onInput={(e) => handlePrice(e)}
                          />
                          <span
                            className="form__label"
                            style={{
                              display: "inline-block",
                              marginBottom: "0.7rem",
                              padding: "0 1rem",
                            }}
                          >
                            To
                          </span>
                          <InputItem
                            title=""
                            name="estimatedShiftPayTo"
                            form={form}
                            onInput={(e) => handlePrice(e)}
                          />{" "}
                        </div>
                        <div style={{ width: "55%" }}>
                          <InputItem
                            title=""
                            name="price"
                            form={form}
                            onInput={(e) => handlePrice(e)}
                            disabled={
                              currentUser && currentUser?.role === "admin"
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <InputItem
                          title="Maximum Task Per Driver"
                          name="maxTaskPerDriver"
                          form={form}
                          type="number"
                          min="0"
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>

                <fieldset>
                  <legend>Timetable</legend>
                  <div className="form__item">
                    <span className="form__label">Shift Window</span>
                    <div className="form__inline-items">
                      {allAvailableTime.map((timeIndex) => {
                        const item = Times[timeIndex];
                        return (
                          <label className="form__check-label" key={timeIndex}>
                            <Controller
                              control={control}
                              name="availableTime"
                              render={({
                                field: { onChange, value, ref },
                                fieldState: { error },
                              }) => (
                                <input
                                  type="checkbox"
                                  name={item.title}
                                  id={item.title}
                                  checked={!!value.includes(timeIndex)}
                                  onChange={() =>
                                    onChange(toggleTime(value, timeIndex))
                                  }
                                  value={timeIndex}
                                  // disabled
                                />
                              )}
                            />
                            {item.title}{" "}
                            <span className="form__check-label-add ml-1">
                              {item.range}
                            </span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </fieldset>

                <div className="form__item form__item_summary form__item_half-margin">
                  <ErrorMessage error={generalError} />
                </div>
              </form>
            </React.Fragment>
          ) : (
            <div className="loading-wrapper">
              <CircularProgress />
            </div>
          )}
        </div>
        <div className="modal__footer">
          <button
            className="btn"
            onClick={() => actions.modal.closeShiftTemplate()}
          >
            Cancel
          </button>
          <div style={{position: 'relative'}}>
            <button
              className="btn btn_accent"
              disabled={isUpdating || isCreating || !isLoaded}
              onClick={handleSubmit(onSubmit)}
            >
              OK 
            </button>
            {isSending}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShiftTemplateModal;
