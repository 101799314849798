import React, { useState, useCallback, useEffect } from "react";
import { Modal, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import "./style.massPayments.scss";
import { FaCheck, FaBan } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import {
  // useGetOrdersQuery,
  useChangeStatusMutation,
  useApproveOrderPaymentMutation,
} from "../../../../api/orders";

import ConfirmDialogModal from "../../../shared/customModal/confirmDialog";
import { useAdhocPaymentMutation } from "../../../../api/drivers";
import { maskingInfo } from "../../helper";
import { useSendSlackMessageMutation } from "../../../../api/slackMessage";
import { actions } from "../../../../redux/store/store";
import { sendErrorMessage } from "../../../../constants/extras/errorHandlers";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "hsl(0deg, 0%, 90%)", //need further import scss variable here
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#4195F5", //need further import scss variable here
  },
}));

const MassPaymentsModal = (props) => {
  const { pathname } = useLocation();
  const { changePaymentsLayout } = props;
  const checkForPayments = changePaymentsLayout
    ? !changePaymentsLayout.includes("AdhocPayments")
    : "";
  const checkForShiftPath = pathname.includes("/shifts");

  const [changeAdhocPaymentsStatus] = useAdhocPaymentMutation();
  const [changeShiftsPaymentStatus] = useChangeStatusMutation();
  const [changeStatusForApproveOrderPayments] =
    useApproveOrderPaymentMutation();
  const [sendSlackMessage] = useSendSlackMessageMutation();

  const [statuses, setStatuses] = useState({});
  const [orders] = useState(props.orders);
  const [showConfirm, setShowConfirm] = useState(false);
  const { role } = useSelector((state) => state.auth.currentUser || {});

  useEffect(() => {
    if (!orders) return;
    setStatuses(orders.map((order) => [order._id, false]));
  }, [orders]);

  const [startPay, setStartPay] = useState(false);
  const [allPaid, setAllPaid] = useState(false);
  const [paidOrdersCount, setPaidOrdersCount] = useState(0);

  const refreshData = () => actions.refetch.setIsDataRefetch(true);

  const pay = useCallback(async () => {
    if (!orders) return;
    setStartPay(true);
    let response;
    for (let order of orders) {
      try {
        setStatuses((statuses) => ({ ...statuses, [order._id]: "processing" }));
        if (pathname.includes("/adhocpayment")) {
          response = await changeAdhocPaymentsStatus({
            status: "pay",
            _id: order._id,
          });
        } else {
          console.log('order....', order.status)
          response = await changeShiftsPaymentStatus({
            status: (order.status=== "complete" || (order.status === "approved" && !order?.isWeeklyPayments && order?.transactionFailedReason) ? 'directpay' : 'pay' ),
            _id: order._id,
          });
        }

        if (response?.data?.transactionFailedReason) {
          sendErrorMessage(`${response?.data?.transactionFailedReason}`)
        }

        if (
          checkForPayments ||
          checkForShiftPath ||
          changePaymentsLayout.includes("AdhocPayments")
        ) {
          setPaidOrdersCount((x) => x + 1);
          if (
            response?.data.transactionStatus === "paid" ||
            response?.data.status === "paid" ||
            response?.data.status === "approved"
          ) {
            setStatuses((statuses) => ({ ...statuses, [order._id]: "paid" }));
          } else if (
            response?.data.transactionStatus === "pending" ||
            response?.data.status === "pending"
          ) {
            setStatuses((statuses) => ({
              ...statuses,
              [order._id]: "pending",
            }));
          } else {
            setStatuses((statuses) => ({
              ...statuses,
              [order._id]: "rejected",
            }));
          }
        }
      } catch (e) {
        if (checkForPayments && !checkForShiftPath) {
          setPaidOrdersCount((x) => x + 1);
          setStatuses((statuses) => ({ ...statuses, [order._id]: "rejected" }));
        }
      }
    }
    const body = {
      type: "shiftpayment",
    };
    if (response && checkForShiftPath && role === "admin") {
      await sendSlackMessage(body);
      refreshData();
    }
    setStartPay(false);
    setAllPaid(true);
  }, [
    orders,
    changeAdhocPaymentsStatus,
    changeShiftsPaymentStatus,
    checkForPayments,
    changeStatusForApproveOrderPayments,
    checkForShiftPath,
    changePaymentsLayout,
    sendSlackMessage,
    role,
  ]);

  let total;
  let weeklypayout;
  if (checkForShiftPath || checkForPayments) {
    total = orders.reduce((accum, item) => accum + item.price, 0);
  } else {
    total = orders.reduce((accum, item) => accum + item.amount, 0);
  }
  if ((checkForShiftPath || checkForPayments) && changePaymentsLayout === "Weekly Payment") {
    weeklypayout = orders.reduce((accum, item) => accum + item.totalPrice, 0);
  }

  return (
    <React.Fragment>
      {showConfirm && (
        <ConfirmDialogModal
          bodyText="Pay for all orders?"
          isOpen
          onClose={() => setShowConfirm(false)}
          confirmAction={() => {
            setShowConfirm(false);
            pay();
          }}
          modalText="PAY"
        />
      )}
      <Modal
        open={props.isOpen}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="MuiModal-root_not-clickable" //for avoid click to backdrop; further need replace by programly/settings; still closing by esc key, need prevent this
      >
        <div className={`modal modal_lg-wide modal_mass-payment after-loading`}>
          <div className="modal__header">
            <h2 className="modal__title">Mass Payments</h2>
          </div>
          <div className="modal__body">
            <div className="mass-payments">
              <div className="mass-payments__btn-holder">
                {!allPaid && (
                  <button
                    disabled={startPay ? true : null}
                    className="btn btn_accent mass-payments__btn-start"
                    onClick={() => setShowConfirm(true)}
                  >
                    {startPay ? (
                      <CircularProgress className="mass-payments__btn-start-icon" />
                    ) : (
                      <React.Fragment>Pay All</React.Fragment>
                    )}
                  </button>
                )}
              </div>

              <div className="mass-payments__progress-holder">
                {orders && (
                  <BorderLinearProgress
                    variant="determinate"
                    value={(100 * paidOrdersCount) / orders.length}
                  />
                )}
              </div>

              <table className="table mass-payments__table table_thead-sticky">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>
                      {checkForPayments || checkForShiftPath
                        ? "Metro"
                        : "Payee Name"}
                    </th>
                    <th style={{ textAlign: "center" }}>
                      {checkForPayments || checkForShiftPath
                        ? "Driver"
                        : "Account Number "}
                    </th>
                    <th className="mass-payments__table-th-amount">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {orders &&
                    orders.map((order) => {
                      return (
                        <tr key={`${order._id}`}>
                          <td className="mass-payments__table-td-status">
                            <div className="mass-payments__status-holder">
                              <div className="mass-payments__status-icon-holder">
                                {(() => {
                                  switch (statuses[order._id]) {
                                    case "paid":
                                    case "pending":
                                    case "processed":
                                    case "approved":
                                    case "success":
                                      return (
                                        <FaCheck className="mass-payments__status-icon mass-payments__status-icon_payed" />
                                      );
                                    case "processing":
                                      return (
                                        <React.Fragment>
                                          <CircularProgress className="mass-payments__status-icon mass-payments__status-icon_pending" />
                                          <div className="mass-payments__cancel-holder">
                                            <button className="btn btn_block btn_reject-outline">
                                              Cancel
                                            </button>
                                          </div>
                                        </React.Fragment>
                                      );
                                    case "rejected":
                                      return (
                                        <MdCancel className="mass-payments__status-icon mass-payments__status-icon_rejected" />
                                      );
                                    case "canceled":
                                      return (
                                        <FaBan className="mass-payments__status-icon mass-payments__status-icon_canceled" />
                                      );
                                    default:
                                      return <React.Fragment></React.Fragment>;
                                  }
                                })()}
                              </div>
                            </div>
                          </td>
                          <td>
                            {checkForPayments || checkForShiftPath
                              ? order.metro
                              : order.payeeName}
                          </td>
                          <td
                            className="mass-payments__table-td-amount"
                            style={{ fontWeight: "500" }}
                          >
                            {checkForPayments
                              ? order &&
                              order.driver &&
                              `${order.driver.firstName} ${order.driver.lastName}`
                              : checkForShiftPath
                                ? order?.from?.name || "-"
                                : maskingInfo(
                                  order?.accountInfo?.depositAccountNumber || ""
                                )}
                          </td>
                          <td className="mass-payments__table-td-amount">
                            {(checkForPayments || checkForShiftPath) && changePaymentsLayout === "Weekly Payment" ?
                              `$${order.totalPrice ? order?.totalPrice : 0} ` :
                              checkForPayments || checkForShiftPath
                                ? `$${order.price ? order?.price : 0} ` : `$${order.amount ? order?.amount : 0}`}
                          </td>
                        </tr>
                      );
                    })}
                  {/* (checkForPayments || checkForShiftPath) && changePaymentsLayout ==="Weekly Payment" */}
                  {/* changePaymentsLayout */}
                  <tr>
                    <th colSpan={"3"} style={{ textAlign: "center" }}>
                      Total
                    </th>
                    <th style={{ textAlign: "center" }}>{(checkForPayments || checkForShiftPath) && changePaymentsLayout === "Weekly Payment" ? `$${weeklypayout.toFixed(2)}` : `$${total}`}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal__footer">
            <button
              disabled={startPay ? true : null}
              className="btn btn_accent"
              onClick={() => props.onClose()}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default MassPaymentsModal;

// await Promise.all(orders.map(async order => {
//   try {
//     setStatuses(statuses => ({ ...statuses, [order._id]: 'pending' }))
//     await changeStatus({ status: 'pay', _id: order._id })
//     setPaidOrdersCount(x => x + 1)
//     setStatuses(statuses => ({ ...statuses, [order._id]: 'paid' }))
//   } catch (e) {
//     setPaidOrdersCount(x => x + 1)
//     setStatuses(statuses => ({ ...statuses, [order._id]: 'rejected' }))
//   }
// }))
