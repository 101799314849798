// React
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import cn from "classnames";
import "../../../styles/_data.scss";
import "./style.scss";

// Third Party LIBRARY
import ReactSelect from "react-select";
import { CSVLink } from "react-csv";
import { IoSearchSharp } from "react-icons/io5";
import InfiniteScroll from "react-infinite-scroll-component";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

// MUI Component
import { CircularProgress, Fade, Menu, MenuItem } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Refresh } from "@mui/icons-material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';

// Constants and Components
import {
  checkerAndCoachingHeader,
  checkerAndShiftHeader,
  checkerHeader,
  coachingHeader,
  driversData,
  header,
  headers,
  inbuildHeaders,
  shiftStatusAndCoachingHeader,
  shiftStatusHeader,
} from "../../../constants/csv/csvDriversData";
import {
  xlsxAllDriversData,
  xlsxChecker,
  xlsxCheckerAndCoachingHeader,
  xlsxCheckerAndShiftHeader,
  xlsxCoachingHeader,
  xlsxDriversData,
  xlsxShiftStatus,
  xlsxShiftStatusAndCoachingHeader,
} from "../../../constants/xlsx/xlsxDriverData";
import {
  convertToCapitalize,
  removeDash,
} from "../../../constants/extras/convertToCapitalize";
import { hideDriverColumn } from "../../../constants/menuLists";
import { dataExport, xlsxDataExport } from "../../../constants/extras/dataExport";
import { handleBackgroundStatusesBadge, handleStatusesBadge } from "../../../constants/extras/statusesBadge";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import Days from "../../../components/shared/WeekSchedule"
import { exportMenu, scheduleTime, weekdays } from "../../../constants/variables/arrays";
import { Times } from "../../../util/time";
import { PAGINATION } from "../../../constants/variables";

// Images and Assets
import { ReactComponent as IntialSignup } from "../../../images/intial-signup.svg"
import { ReactComponent as Onboarding } from "../../../images/onboard.svg"
import { ReactComponent as BackgroundChecker } from "../../../images/checker.svg"
import { ReactComponent as CompleteShift } from "../../../images/complete-shift.svg"
import filterLogo from "../../../images/filter-icon.svg"
import details_icon from "../../../images/details.svg"
import warningIcon from "../../../images/warning.svg"

// Redux
import { actions } from "../../../redux/store/store";
import { getDriversList } from "../../../api/drivers";
import { useGetSavedFilterDataQuery } from "../../../api/savedFilter";

// Dynamic Imports
const Table = React.lazy(() => import("../../../components/shared/Table"));
const CustomDrawer = React.lazy(() => import("../../../components/shared/customDrawer/driver"));

const { COMMON__LIMIT } = PAGINATION;

export default function Drivers() {
  const location = useLocation();
  const { pathname } = location;

  // =================== START PREDEFINED VARIABLE ===================
  const defaultHideDriverColumn = ["Days & Time", "Time Amount"]
  const data = [...hideDriverColumn]
  // =================== END PREDEFINED VARIABLE ===================

  // =================== START USESELECTOR HOOKS ===================
  const isRefetch = useSelector((state) => state.refetch.value);
  const currentUser = useSelector((state) => state.auth.currentUser)
  const modalOrderOpen = useSelector((state) => state.modal.driver.open);
  const modalCoachingHistory = useSelector((state) => state.modal.coachingHistory.open);
  // =================== END USESELECTION HOOKS ===================


  // =================== START USESTATE HOOKS ===================
  const [hasMore, setHasMore] = useState(true);
  const [activeItem, setActiveItem] = useState();
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(2);
  const [limit] = useState(COMMON__LIMIT);
  const [loadingCsv, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [saveData, setSaveData] = useState("")
  const [openDrawer, setOpenDrawer] = useState(false)
  const [isFirstDriver, setIsFirstDriver] = useState(false)
  const [isShiftStatus, setIsShiftStatus] = useState(false)
  const [isOffBoardStatus, setIsOffBoardStatus] = useState(false)
  const [isBackgroundCheck, setIsBackgroundCheck] = useState(false)
  const [firstDriver, setFirstDriver] = useState(false);
  const [shiftStatus, setShiftStatus] = useState(false)
  const [offBoardStatus, setOffBoardStatus] = useState(false)
  const [backgroundCheck, setBackgroundCheck] = useState(false)
  const [isApply, setIsApply] = useState(false)
  const [coaching, setCoaching] = useState(false)
  const [isCoaching, setIsCoaching] = useState(false)
  const [btReview, setBtReview] = useState(false)
  const [isBtReview, setIsBtReview] = useState(false)
  const [expired, setExperied] = useState(false);
  const [isExpired, setIsExperied] = useState(false);
  const [fromShiftRange, setFromShiftRange] = useState("");
  const [toShiftRange, setToShiftRange] = useState("");
  const [fromScoreRange, setFromScoreRange] = useState("")
  const [toScoreRange, setToScoreRange] = useState("")
  const [selectState, setSelectState] = useState([])
  const [metro, setMetro] = useState([])
  const [statusFilter, setStatusFilter] = useState([])
  const [hideColumn, setHideColumn] = useState(defaultHideDriverColumn)
  const [showColumn, setShowColumn] = useState(hideDriverColumn)
  const [columndata, setColumnData] = useState([])
  const [getID, setGetID] = useState("")
  const [anchorEl, setAnchorEl] = useState(null);
  const [csvData, setCsvData] = useState(false);
  const csvInstance = useRef();
  const [companyData, setCompanyData] = useState([])
  const [isTotal, setIsTotal] = useState("")
  const [days, setDays] = useState([])
  const [time, setTime] = useState([])
  const [timeAmount, setTimeAmount] = useState("")
  const [isExport, setIsExport] = useState(false);
  // =================== END USESTATE HOOKS ===================


  // =================== START SHIFT DATE RANGE ===================
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [prevDate, setPrevDate] = useState(state);
  // =================== END SHIFT DATE RANGE ===================


  // =================== START DRIVER ONBOARD DATE RANGE ===================
  const [showOnboardPicker, setShowOnboardPicker] = useState(false)
  const [onboardingFrom, setOnboardingFrom] = useState("")
  const [onboardingTo, setOnboardingTo] = useState("")
  const [onboardingstate, setOnboardingState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [onboardingPrevDate, setOnboardingPrevDate] = useState(onboardingstate);
  // =================== END DRIVER ONBOARD DATE RANGE ===================


  // =================== START DRIVER OFFBOARD DATE RANGE ===================
  const [showOffboardPicker, setShowOffboardPicker] = useState(false)
  const [offboardedFrom, setOffboardedFrom] = useState("")
  const [offboardedTo, setOffboardedTo] = useState("")
  const [offboardingstate, setOffboardingState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [offboardingPrevDate, setOffboardingPrevDate] = useState(offboardingstate);
  // =================== END DRIVER OFFBOARD DATE RANGE ===================


  // =================== START SHIFT INTEREST ROUTE DATE RANGE ===================
  const [showIntrestDrivers, setShowIntrestDrivers] = useState(false)
  const [intrestDriversFromDate, setIntrestDriversFromDate] = useState("")
  const [intrestDriversToDate, setIntrestDriversToDate] = useState("")
  const [intrestDriverState, setIntrestDriverState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [intrestDriversPrevDate, setIntrestDriversPrevDate] = useState(intrestDriverState);
  // =================== END SHIFT INTEREST ROUTE DATE RANGE ===================


  // =================== START DRIVER LICENSE EXPIRE DATE RANGE ===================
  const [showExpired, setShowExpired] = useState(false)
  const [expiredFromDate, setExpiredFromDate] = useState("")
  const [expiredToDate, setExpiredToDate] = useState("")
  const [expiredState, setExpiredState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [expiredPrevDate, setexpiredPrevDate] = useState(expiredState);
  // =================== END DRIVER LICENSE EXPIRE DATE RANGE ===================
  
  
  // =================== START COMMON ===================
  const open = Boolean(anchorEl);
  const metroData = metro?.map(item => item.value)
  const status = statusFilter?.map(item => item.value)
  const stateData = selectState?.map(item => item.value)
  const isNotSelected = companyData.some((item, i) => item.label === "Not Selected")
  const company = companyData ? companyData?.filter((item, i) => item.label !== "Not Selected").map(item => item.id) : []
  const daysData = days?.map((e) => e?.id)
  const timeData = time?.map((e) => e?.id)
  // =================== END COMMON ===================


  // =================== START GET API CALLING ===================
  const { data: getsavedData } = useGetSavedFilterDataQuery({ id: currentUser?._id, screen: "driver" }, {
    refetchOnMountOrArgChange: true,
    skip: !currentUser?._id
  })
  
  const getDriverData = async (page, limit, search = "") => {
    const data = await getDriversList(
      page,
      limit,
      status,
      search,
      metroData,
      fromDate,
      toDate,
      isFirstDriver,
      isShiftStatus,
      isBackgroundCheck,
      fromShiftRange,
      toShiftRange,
      fromScoreRange,
      toScoreRange,
      stateData,
      onboardingFrom,
      onboardingTo,
      offboardedFrom,
      offboardedTo,
      intrestDriversFromDate,
      intrestDriversToDate,
      company,
      isNotSelected,
      daysData,
      timeData,
      timeAmount,
      coaching,
      btReview,
      expiredFromDate,
      expiredToDate,
      expired,
      isExport
    );
    return data;
  };
  // =================== END GET API CALLING ===================


  // =================== START PREDEFINED OBJECT ===================
  const propsobj = {
    isFirstDriver,
    setIsFirstDriver,
    isShiftStatus,
    setIsShiftStatus,
    setIsOffBoardStatus,
    isBackgroundCheck,
    setIsBackgroundCheck,
    fromShiftRange,
    setFromShiftRange,
    toShiftRange,
    setToShiftRange,
    fromScoreRange,
    setFromScoreRange,
    toScoreRange,
    setToScoreRange,
    selectState,
    setSelectState,
    metro,
    setMetro,
    statusFilter,
    setStatusFilter,
    fromDate,
    toDate,
    showDateRangePicker,
    setState,
    state,
    setFromDate,
    setToDate,
    setShowDateRangePicker,
    showOnboardPicker,
    setShowOnboardPicker,
    setOnboardingState,
    onboardingstate,
    onboardingFrom,
    onboardingTo,
    setOnboardingFrom,
    setOnboardingTo,

    showOffboardPicker,
    setShowOffboardPicker,
    setOffboardingState,
    offboardingstate,
    offboardedFrom,
    offboardedTo,
    setOffboardedFrom,
    setOffboardedTo,

    showIntrestDrivers,
    setShowIntrestDrivers,
    intrestDriversFromDate,
    setIntrestDriversFromDate,
    intrestDriversToDate,
    setIntrestDriversToDate,
    intrestDriverState,
    setIntrestDriverState,
    saveData,
    hideColumn,
    columndata,
    setColumnData,
    getID,
    data,
    setCompanyData,
    companyData,
    days,
    setDays,
    time,
    setTime,
    timeAmount,
    setTimeAmount,
    isCoaching,
    setIsCoaching,
    isBtReview,
    setIsBtReview,
    showExpired,
    setShowExpired,
    expiredFromDate,
    setExpiredFromDate,
    expiredToDate,
    setExpiredToDate,
    expiredState,
    setExpiredState,
    isExpired,
    setIsExperied,
  }

  const styles = {
    placeholder: (base) => ({
      ...base,
      fontSize: "0.9rem",
      fontWeight: "normal"
    }),
    control: (styles) => ({
      ...styles,
      borderRadius: "5px",
      boxShadow: "none",
      height: "2.9rem",
      "&:hover": {
        border: "2px solid #dadada",
      },
      border: "2px solid #dadada",
      fontSize: "1rem",
      width: "12rem",
    }),
  }
  // =================== END PREDEFINED OBJECT ===================


  // =================== START COMMON FUNCTION ===================
  const updateDriverState = (data) => {
    setIsTotal(data?.total);
    setFilteredDrivers(data.result);
    setPage(2);
    if (data?.result?.length < limit) setHasMore(false);
  };

  const fetchDriverData = async () => {
    try {
      setDisable(true);
      const data = await getDriverData(1, limit, searchQuery);
      updateDriverState(data);
      // actions.drivers.addDrivers(data.result);
    } catch (error) {
      console.error("Failed to fetch driver data:", error);
    } finally {
      setDisable(false);
      actions.refetch.setIsDataRefetch(false);
      setIsLoading(false);
    }
  };

  const handleSearchingDriver = useCallback(
    async (c) => {
      const search = c === "CLEAR" ? "" : searchQuery;
      setFilteredDrivers([]);
      setIsTotal([])
      setHasMore(true);
      setPage(1);
      setDisable(true);
      const data = await getDriverData(1, limit, search)
      updateDriverState(data);
      setDisable(false);
    },
    [limit, searchQuery]
  );

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
    if (e.target.value === "") handleSearchingDriver("CLEAR");
  }, []);

  const handleClearSearch = () => {
    if (!disable) {
      setSearchQuery("");
      handleSearchingDriver("CLEAR");
    }
  };

  const fetchMoreDrivers = async () => {
    setDisable(true);
    let data = await getDriverData(page, limit, searchQuery)
    setIsTotal(data?.total)
    setDisable(false);
    setFilteredDrivers([...filteredDrivers, ...data.result]);
    if (data?.result?.length === 0 || data?.result?.length < limit) setHasMore(false);
    setPage((prevPage) => prevPage + 1);
  };

  const toggleDatePicker = (setShowPicker, setDateState, date, prevDate) => {
    setShowPicker(prev => !prev);
    setDateState(date.length ? prevDate : [{
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    }]);
  };

  const handleTimeRange = (stateType, setPrevState, setStartDate, setEndDate, setShowPicker) => {
    const startDate = moment(stateType[0].startDate).format("yyyy-MM-DD");
    const endDate = moment(stateType[0].endDate).format("yyyy-MM-DD");

    setPrevState(stateType);
    setStartDate(startDate);
    setEndDate(endDate);
    setShowPicker(false);
  }; 
  
  const getActiveItem = (id) => {
    setActiveItem(id);
  };
  // =================== END COMMON FUNCTION ===================


  // =================== START COMMON USEEFFECT ===================
  useEffect(() => {
    actions.metro.selectMetro("");
    actions.refetch.setIsDataRefetch(true);
  }, [pathname]);

  useEffect(() => {
    let timer;

    const closeActiveItem = () => {
      setActiveItem(false);
    };

    if (!modalOrderOpen && !modalCoachingHistory) {
      timer = setTimeout(closeActiveItem, 1500);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [modalOrderOpen, modalCoachingHistory]);

  useEffect(() => {
    if (!isRefetch) return;

    setIsLoading(true);
    setHasMore(true);
    setFilteredDrivers([]);
    setIsTotal([])
    setPage(1);

    fetchDriverData();
  }, [isRefetch]);

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      csvInstance.current.link.click();
      setCsvData(false);
    }
  }, [csvData]);
  // =================== END COMMON USEEFFECT ===================


  // =================== START ALL DATE RANGE HANDLER FUNCTION ===================
  const handleCustomRange = () => {
    toggleDatePicker(setShowDateRangePicker, setState, fromDate, prevDate);
  }

  const onBoardingRange = () => {
    toggleDatePicker(setShowOnboardPicker, setOnboardingState, onboardingFrom, onboardingPrevDate);
  }

  const offBoardingRange = () => {
    toggleDatePicker(setShowOffboardPicker, setOffboardingState, offboardedFrom, offboardingPrevDate);
  }

  const intrestDriverRange = () => {
    toggleDatePicker(setShowIntrestDrivers, setIntrestDriverState, intrestDriversFromDate, intrestDriversPrevDate);
  }

  const expiryRange = () => {
    if (!isExpired) {
      toggleDatePicker(setShowExpired, setExpiredState, expiredFromDate, expiredPrevDate);
    }
  }
  // =================== END ALL DATE RANGE HANDLER FUNCTION ===================


  // =================== START ALL TIME RANGE HANDLER FUNCTION ===================
  const handleTimeRangeSelection = async () => {
    handleTimeRange(state, setPrevDate, setFromDate, setToDate, setShowDateRangePicker);
  }

  const handleOnboardedTimeRange = async () => {
    handleTimeRange(onboardingstate, setOnboardingPrevDate, setOnboardingFrom, setOnboardingTo, setShowOnboardPicker);
  }

  const handleOffboardedTimeRange = async () => {
    handleTimeRange(offboardingstate, setOffboardingPrevDate, setOffboardedFrom, setOffboardedTo, setShowOffboardPicker);
  }

  const handleIntrestDriverTimeRange = async () => {
    handleTimeRange(intrestDriverState, setIntrestDriversPrevDate, setIntrestDriversFromDate, setIntrestDriversToDate, setShowIntrestDrivers);
  }

  const handleExpiryTimeRange = async () => {
    handleTimeRange(expiredState, setexpiredPrevDate, setExpiredFromDate, setExpiredToDate, setShowExpired);
  }
  // =================== END ALL TIME RANGE HANDLER FUNCTION ===================


  // =================== START CSV AND XLSX EXPORT FUNCTIONALITY ===================
  const getXlsxData = (data) => {
    if (shiftStatus && backgroundCheck && coaching) return xlsxAllDriversData(data, showColumn);
    if (shiftStatus && !backgroundCheck && !coaching) return xlsxShiftStatus(data, showColumn);
    if (!shiftStatus && backgroundCheck && !coaching) return xlsxChecker(data, showColumn);
    if (shiftStatus && backgroundCheck && !coaching) return xlsxCheckerAndShiftHeader(data, showColumn);
    if (!shiftStatus && backgroundCheck && coaching) return xlsxCheckerAndCoachingHeader(data, showColumn);
    if (shiftStatus && !backgroundCheck && coaching) return xlsxShiftStatusAndCoachingHeader(data, showColumn);
    if (!shiftStatus && !backgroundCheck && coaching) return xlsxCoachingHeader(data, showColumn);
    return xlsxDriversData(data, showColumn);
  };

  const getCsvHeaders = () => {
    const baseHeaders = headers?.filter((elem) => {
      const label = (elem?.label === "Days" || elem?.label === "Time") ? "Days & Time" : elem?.label;
      return showColumn?.includes(label);
    });
    
    const headerTypes = [
      { condition: !shiftStatus && !backgroundCheck && !coaching, headers: [...baseHeaders, ...inbuildHeaders] },
      { condition: shiftStatus && backgroundCheck && coaching, headers: [...baseHeaders, ...header, ...inbuildHeaders] },
      { condition: shiftStatus && !backgroundCheck && !coaching, headers: [...baseHeaders, ...shiftStatusHeader, ...inbuildHeaders] },
      { condition: !shiftStatus && backgroundCheck && !coaching, headers: [...baseHeaders, ...checkerHeader, ...inbuildHeaders] },
      { condition: shiftStatus && backgroundCheck && !coaching, headers: [...baseHeaders, ...checkerAndShiftHeader, ...inbuildHeaders] },
      { condition: !shiftStatus && backgroundCheck && coaching, headers: [...baseHeaders, ...checkerAndCoachingHeader, ...inbuildHeaders] },
      { condition: shiftStatus && !backgroundCheck && coaching, headers: [...baseHeaders, ...shiftStatusAndCoachingHeader, ...inbuildHeaders] },
      { condition: !shiftStatus && !backgroundCheck && coaching, headers: [...baseHeaders, ...coachingHeader, ...inbuildHeaders] },
    ];

    const matchedType = headerTypes.find(item => item.condition);
    return matchedType ? matchedType.headers : [...baseHeaders, ...inbuildHeaders];
  };

  const downloadDriversData = async () => {
    const data = await getDriverData(undefined, undefined, searchQuery);
    return data?.result && Array.isArray(data.result) && data?.result?.length > 0 ? data.result : [];
  };

  const exportData = async (value) => {
    setAnchorEl(null);
    setLoading(true);

    const download = await downloadDriversData();
    const filename = `Drivers_Information.${value}`;
    const headers = getCsvHeaders();
    
    setLoading(false);
    setIsExport(false);

    if (value === "csv") {
      const data = driversData(download);
      const obj = dataExport(data, filename, headers);
      setCsvData(obj);
    } else if (value === "xlsx") {
      const data = getXlsxData(download);
      xlsxDataExport(data, filename);
    }
  };
  // =================== END CSV AND XLSX EXPORT FUNCTIONALITY ===================

  const onSaveDataChange = (savedata) => {
    setIsTotal([])
    const getsingleData = getsavedData?.filter((item) => item?.filtername === savedata?.value)
    const filterData = getsingleData && getsingleData[0]?.filterdata
    setGetID(getsingleData && getsingleData[0]?._id)
    const columnData = filterData?.showcolumn?.filter((item) => data.includes(item))
    const hidedata = filterData?.hidecolumn?.filter((item) => data.includes(item))
    const metroData = filterData?.metro?.map((item) => { return { value: item, label: item, } })
    const state = filterData?.state?.map((item) => { return { value: item, label: item, } })
    const status = filterData?.status?.map((item) => { return { value: item, label: item, } })
    const dayData = weekdays?.filter((el, index) => filterData?.availableDays?.some((item) => item === index)).map((i, id) => ({ value: i, label: i, id: id }))
    const timeData = scheduleTime?.filter((el, index) => filterData?.availableTime?.some((item) => item === index)).map((i, id) => ({ value: i, label: i, id: id }))
    const amountData = { label: filterData?.timeAmount?.title, value: filterData?.timeAmount?.title, id: filterData?.timeAmount?.id }
    let company = filterData?.company ? filterData?.company?.map((item) => {
      return {
        value: `${item?.companyCode ? item?.companyCode : ""} ${item?.companyName && item?.companyCode ? "-" : ""} ${item?.companyName ? item?.companyName : ""}`,
        label: `${item?.companyCode ? item?.companyCode : ""} ${item?.companyName && item?.companyCode ? "-" : ""} ${item?.companyName ? item?.companyName : ""}`,
        companyName: item.companyName,
        id: item._id
      }
    }) : []
    filterData?.isNotSelected && company?.unshift({ label: "Not Selected", value: "Not Selected" })

    setMetro(metroData || [])
    setFromScoreRange(filterData?.scoreFrom || "")
    setToScoreRange(filterData?.scoreTo || "")
    setFromShiftRange(filterData?.orderCountFrom || "")
    setToShiftRange(filterData?.orderCountTo || "")
    setIsFirstDriver(filterData?.firstOrderDriver || false);
    setIsShiftStatus(filterData?.isShiftCount || false);
    setOffBoardStatus(filterData?.status.includes("offboarded") || false);
    setIsBackgroundCheck(filterData?.isBackgroundCheck || false)
    setIsCoaching(filterData?.isCoaching || false)
    setIsBtReview(filterData?.isbtReviewUnverified || false)
    setCoaching(filterData?.isCoaching || false)
    setBtReview(filterData?.isbtReviewUnverified || false)
    setExperied(filterData?.isExpired || false)
    setIsExperied(filterData?.isExpired || false)
    setBackgroundCheck(filterData?.isBackgroundCheck || false)
    setSelectState(state || [])
    setStatusFilter(status || [])
    setFromDate(filterData?.shifFromDate || "")
    setToDate(filterData?.shifToDate || "")
    setOnboardingFrom(filterData?.onBoardFrom || "");
    setOnboardingTo(filterData?.onBoardTo || "");
    setOffboardedFrom(filterData?.offBoardFrom || "");
    setOffboardedTo(filterData?.offBoardTo || "");
    setIntrestDriversFromDate(filterData?.InterestedFrom || "");
    setIntrestDriversToDate(filterData?.InterestedTo || "");
    setExpiredFromDate(filterData?.expirationFromDate || "")
    setExpiredToDate(filterData?.expirationToDate || "")
    setColumnData(columnData || data)
    !openDrawer && setShowColumn(columnData || data)
    setHideColumn(hidedata || defaultHideDriverColumn)
    setSaveData(savedata)
    setCompanyData(company || [])
    setDays(filterData?.availableDays?.length && dayData || []);
    setTime(filterData?.availableTime?.length && timeData || [])
    setTimeAmount(filterData?.timeAmount?.title ? amountData : null)
  }



  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchingDriver();
    }
  };

  const hideShowColumn = useCallback(
    (name) => {
      const result = showColumn?.some((item) => (item === name))
      return result
    },
    [showColumn]
  );

  const onBoarding = (item) => {
    return (
      <Popover id="onboardingAt" >
        <Popover.Body>
          <p className="mb-0">Tax Fill Status: {item?.onboardingTaxFillStatus ? <CheckIcon style={{ color: "green", fontSize: "1.2rem" }} />
            : <ClearIcon style={{ color: "red", fontSize: "1.2rem" }} />} </p>
          <p className="mb-0">Insurance Fill Status:	{item?.onboardingInsuranceFillStatus ? <CheckIcon style={{ color: "green", fontSize: "1.2rem" }} />
            : <ClearIcon style={{ color: "red", fontSize: "1.2rem" }} />} </p>
          {/* <p className="mb-0">Videos Fill Status: {item?.onboardingVideosFillStatus ? <CheckIcon style={{ color: "green", fontSize: "1.2rem" }} />
            : <ClearIcon style={{ color: "red", fontSize: "1.2rem" }} />} </p> */}
          <p className="mb-0">Background Fill Status: {item?.onboardingBackgroundFillStatus ? <CheckIcon style={{ color: "green", fontSize: "1.2rem" }} />
            : <ClearIcon style={{ color: "red", fontSize: "1.2rem" }} />}
            {!item?.checkrBackgroundStatus ? item?.consents?.consentId ? <span class="text-green">(C)</span> : <span class="text-red">(NC) </span> : null}</p>
          <p className="mb-0">Deposit Fill Status: {item?.onboardingDepositFillStatus ? <CheckIcon style={{ color: "green", fontSize: "1.2rem" }} />
            : <ClearIcon style={{ color: "red", fontSize: "1.2rem" }} />} </p>
          <p className="mb-0">Driver Licence Fill Status: {item?.onboardingLicenceFillStatus ? <CheckIcon style={{ color: "green", fontSize: "1.2rem" }} />
            : <ClearIcon style={{ color: "red", fontSize: "1.2rem" }} />} </p>
          <p className="mb-0">Vehicle Fill Status: {item?.onboardingVehicleFillStatus ? <CheckIcon style={{ color: "green", fontSize: "1.2rem" }} />
            : <ClearIcon style={{ color: "red", fontSize: "1.2rem" }} />} </p>
          <p className="mb-0">Driver Badge Fill Status: {item?.onboardingDriverBadgeFillStatus ? <CheckIcon style={{ color: "green", fontSize: "1.2rem" }} />
            : <ClearIcon style={{ color: "red", fontSize: "1.2rem" }} />} </p>
        </Popover.Body>
      </Popover>
    )
  }

  const licenceExpire = (item) => {
    return (
      <Popover id="onboardingAt" style={{ maxWidth: "none" }}>
        <Popover.Body >
          {item?.isLicenceExpire && <p className="mb-0">{`Your driver's license ${item?.licenceExpiredDays >= 0 ? "expires in " + item?.licenceExpiredDays + ' days' : "expired " + Math.abs(item?.licenceExpiredDays) + " days ago"}`}</p>}
          {item?.isInsuranceExpire && <p className="mb-0">{`Your vehicle's insurance ${item?.insuranceExpiredDays >= 0 ? "expires in " + item?.insuranceExpiredDays + ' days' : "expired " + Math.abs(item?.insuranceExpiredDays) + " days ago"}`}</p>}
        </Popover.Body>
      </Popover>
    )
  }

  const backgroundChecker = (item, val) => {
    if (!item?.checkrBackgroundStatus) return val === "stroke" ? "#838383" : "list2"
    if (item?.checkrBackgroundStatus === "pending") return val === "stroke" ? "#E2991F" : "list3"
    if (item?.checkrBackgroundStatus !== "pending") return val === "stroke" ? "#007bff" : "list"
  }

  const onboardingStyle = (item, val) => {
    if (!item?.onboardingAt && (item?.onboardingTaxFillStatus || item?.onboardingInsuranceFillStatus || item?.onboardingVideosFillStatus || item?.onboardingBackgroundFillStatus
      || item?.onboardingDepositFillStatus || item?.onboardingLicenceFillStatus || item?.onboardingVehicleFillStatus || item?.onboardingDriverBadgeFillStatus)) {
      return val === "stroke" ? "#E2991F" : "list3"
    } else if (item?.onboardingAt) {
      return val === "stroke" ? "#007bff" : "list"
    } else {
      return val === "stroke" ? "#838383" : "list2"
    }
  }

  const columns = [
    hideShowColumn("Name") ? {
      name: "firstName",
      label: "Name",
      sortable: true,
      className: "w-10",
      renderer: (item) => {
        return (
          <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            {(item?.isLicenceExpire || item?.isInsuranceExpire) &&
              <div style={{ cursor: "pointer" }}>
                {item?.status === "approved" ?
                  (<OverlayTrigger overlay={licenceExpire(item)} placement="top" >
                    <img style={{ width: "20px" }} src={warningIcon} />
                  </OverlayTrigger>)
                  : null}
              </div>}
            <div>
              {`${item.firstName ? item.firstName : ""} ${item.lastName ? item.lastName : ""}`}
            </div>
          </span>
        )
      },
    } : { label: "HIDE" },
    hideShowColumn("Username") ? {
      name: "username", label: "Username", sortable: true, maxWidth: "180px",
      renderer: (item) => {
        return (
          item.username ?
            firstDriver ?
              <span className="username_styling"><span className="dot" style={{ background: item.cancelled ? "red" : "green" }}></span><span style={{ width: '80%' }}>{item.username}</span></span>
              : <span className="username_table_view">{item.username}</span>
            : ""
        );
      },
    } : { label: "HIDE" },
    hideShowColumn("Company") ? {
      name: "company", label: "Company", sortable: true, maxWidth: "200px",
      renderer: (item) =>
        `${item.company?.companyCode ? item.company.companyCode : ""} ${item.company?.companyCode && item.company.companyName ? "-" : ""} 
        ${item.company?.companyName ? item.company.companyName : ""}`,
    } : { label: "HIDE" },
    hideShowColumn("Phone") ? {
      name: "phone",
      label: "Phone",
      sortable: true,
      renderer: (item) => {
        return <span className="phone_table_view">{item?.phone}</span>
      }
    } : { label: "HIDE" },
    hideShowColumn("Metro") ? { name: "metro", label: "Metro", sortable: true, maxWidth: "133px", } : { label: "HIDE" }, ,
    hideShowColumn("Shifts") ? {
      name: "orderCount",
      label: "Shifts",
      className: "table__td_centered table__td_min-horiz-padding",
      sortable: true,
    } : { label: "HIDE" },
    hideShowColumn("Shift Last Date") ? {
      name: 'shiftLastDate',
      label: 'Shift Last Date',
      sortable: true,
      renderer: (item) =>
        item?.shiftLastDate ? <span>{moment(item?.shiftLastDate).utc().format("MM/DD/YYYY")}</span> : "",
    } : { label: "HIDE" },
    hideShowColumn("Score") ? {
      name: "score",
      label: "Score",
      className: "table__td_centered table__td_min-horiz-padding",
      sortable: true,
      renderer: (item) =>
        item?.score ? <span className="text-danger">{item?.score}</span> : "",
    } : { label: "HIDE" },
    hideShowColumn("Status") ? {
      name: "status",
      label: "Status",
      sortable: true,
      className: "table__td_centered table__td_min-horiz-padding",
      renderer: (item) => {
        return (
          <span
            className="textStyleForStatus"
            style={handleStatusesBadge(
              item?.isBlocked ? "blocked" : item?.status
            )}
          >
            {convertToCapitalize(
              removeDash(item?.isBlocked ? "blocked" : item?.status)
            )}
          </span>
        );
      },
    } : { label: "HIDE" },
    hideShowColumn("Created") ? {
      name: "created",
      label: "Created",
      sortable: true,
      maxWidth: "175px",
      renderer: (item) => moment(item.created).format("MM/DD/YYYY, hh:mm A"),
    } : { label: "HIDE" },
    hideShowColumn("Date Onboard Complete") ?
      {
        name: "onboardingAt", label: "Date Onboard Complete", sortable: true, maxWidth: "148px",
        renderer: (item) => item.onboardingAt ? moment(item.onboardingAt).format("MM/DD/YYYY, hh:mm A") : '-',
      } : { label: "HIDE" },
      offBoardStatus ?
      {
        name: "offboardedAt",
        label: "OffBoard At",
        sortable: true,
        // maxWidth: "220px",
        className: "table__td_centered table__td_min-horiz-padding",
        renderer: (item) => item.offboardedAt ? moment(item.offboardedAt).format("MM/DD/YYYY, hh:mm A") : '-',
      } : { label: "HIDE" },
    hideShowColumn("Days & Time") ? {
      name: "availableDays", label: "Days & Time", sortable: true,
      renderer: (item) => {
        const timeData = Times?.filter((elem, index) => item?.availableTime?.includes(index))
        return (
          <>
            <Days {...item} />
            <div className="mt-2 d-flex" style={{ gap: "2px", flexWrap: "wrap" }} >
              {
                timeData?.map((e, i) => {
                  return (
                    <div key={i} style={{ fontSize: "12px" }} >{e?.range}</div>
                  )
                })
              }
            </div>

          </>
        )
      }
    } : { label: "HIDE" },
    hideShowColumn("Time Amount") ? {
      name: "timeAmount", label: "Time Amount", sortable: true,
      renderer: (item) => item?.timeAmount?.title || "-"
    } : { label: "HIDE" },
    hideShowColumn("Pipeline Stage") ? {
      name: "piplineStage", label: "Pipeline Stage", sortable: true,
      renderer: (item) => {
        return (
          <>
            <div className="track">
              <ul id="progress" className="text-center" >
                <OverlayTrigger overlay={<Popover id="intialSignup"><Popover.Body><strong>Initial Sign Up</strong></Popover.Body></Popover>}>
                  <li className={`pr-1 ${onboardingStyle(item, "class")} `} >
                    <IntialSignup stroke='#007bff' />
                  </li>
                </OverlayTrigger>
                <OverlayTrigger overlay={onBoarding(item)} placement="top" >
                  <li className={`pl-2 pr-1  ${backgroundChecker(item, "class")}`} >
                    <Onboarding stroke={onboardingStyle(item, "stroke")} />
                  </li>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Popover id="checkrBackgroundStatus"><Popover.Body>
                  <strong>{(!item?.checkrBackgroundStatus && "Initial Background Check") ||
                    (item?.checkrBackgroundStatus === "Pending" && `${item?.checkrBackgroundStatus} Background Check`) || (item?.checkrBackgroundStatus !== "Pending" && `${item?.checkrBackgroundStatus} Background Check`)}</strong>
                </Popover.Body></Popover>}>
                  <li className={` pl-2 pr-1 ${!item?.orderCount > 0 ? "list2" : "list"}`} >
                    <BackgroundChecker stroke={backgroundChecker(item, "stroke")} />
                  </li>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Popover id="orderCount"><Popover.Body>
                  <strong>{item?.orderCount > 0 ? "Completed Shift" : "Not Completed Shift"}</strong></Popover.Body></Popover>}>
                  <li className="pl-2 " >
                    <CompleteShift stroke={`${item?.orderCount > 0 ? "#007bff" : "#838383"}`} />
                  </li>
                </OverlayTrigger>
              </ul>
            </div>
          </>
        )
      },
    } : { label: "HIDE" },
    hideShowColumn("Notes") ? { name: "internalNote", label: "Notes", sortable: true, maxWidth: "220px", renderer: (item) => <span className={!firstDriver && "notes_table_view"}>{item.internalNote}</span> } : { label: "HIDE" },
    coaching ?
      {
        name: "coachedNumber",
        label: "Coached number",
        sortable: true,
        className: "table__td_centered table__td_min-horiz-padding",
        renderer: (item) => item?.coachedNumber ? <span className="text-danger">{item?.coachedNumber}</span> : "",
      } : { label: "HIDE" },
    coaching ?
      {
        name: "lastCoached",
        label: "Last coach",
        sortable: true,
        className: "table__td_centered table__td_min-horiz-padding",
        renderer: (item) => item?.lastCoached ? <span>{moment(item?.lastCoached).utc().format("MM/DD/YYYY")}</span> : "",
      } : { label: "HIDE" },
    coaching ?
      {
        name: "maxNumberOfCoaches",
        label: "Number of coaches",
        sortable: true,
        className: "table__td_centered table__td_min-horiz-padding",
        renderer: (item) => item?.maxNumberOfCoaches ? `${item?.maxNumberOfCoaches} (${item?.maxNumberOfCoachesCounts})` : "-",
      } : { label: "HIDE" },

    coaching ?
      {
        name: "coaching",
        className: "table__td_have-btn align-center",
        renderer: (item) => {
          return (
            <button
              className="btn btn_block btn_accent customized-details-btn"
              type="button"
              onClick={() => {
                actions.modal.openCoachingHistory(item);
                getActiveItem(item._id);
              }}
            >
              Coaching
            </button>
          );
        },
      } : { label: "HIDE" },
    {
      name: "details",
      className: "table__td_have-btn py-1",
      renderer: (item) => (
        <div
          onClick={() => {
            actions.modal.openDriver(item._id);
            getActiveItem(item._id);
          }}
          style={{ fontSize: "85%", padding: "0 0.3rem", cursor: "pointer" }}
        >
          <img src={details_icon} alt="" width="20px" />
        </div>
      ),
    },
    backgroundCheck ?
      {
        name: "candidateId",
        label: "Checker Id",
        sortable: true,
        // maxWidth: "220px",
        className: "table__td_centered table__td_min-horiz-padding",
      } : { label: "HIDE" },
    backgroundCheck ?
      {
        name: "checkrBackgroundStatus",
        label: "Checker Status",
        sortable: true,
        className: "table__td_centered table__td_min-horiz-padding",
        renderer: (item) => {
          return (
            item.checkrBackgroundStatus && <span
              className="textStyleForStatus"
              style={handleBackgroundStatusesBadge(item.checkrBackgroundStatus)}
            >
              {item.checkrBackgroundStatus}
            </span>
          );
        },
      } : { label: "HIDE" },
    shiftStatus ?
      {
        name: "shiftStatus",
        label: "Shift Status",
        sortable: true,
        // maxWidth: "220px",
        className: "table__td_centered table__td_min-horiz-padding",
        renderer: (item) => {
          return (
            <>
              <div className="d-flex" style={{ gap: "10px", flexWrap: "wrap" }}>
                {item?.rejectShiftCount > 0 && <span style={handleStatusesBadge("rejected")}> rejected : {item?.rejectShiftCount}</span>}
                {item?.assigned && <span style={handleStatusesBadge("assigned")}> assigned : {item?.assigned}</span>}
                {item?.delivery_started &&
                  <span style={handleStatusesBadge("delivery_started")}> D.Started : {item?.delivery_started}</span>}
                {item?.complete && <span style={handleStatusesBadge("complete")}> complete : {item?.complete}</span>}
                {item?.waiting_for_approval &&
                  <span style={handleStatusesBadge("approved")}> approval : {item?.waiting_for_approval}</span>}
                {item?.paid && <span style={handleStatusesBadge("paid")}> paid : {item?.paid}</span>}
                {item?.cancelled && <span style={handleStatusesBadge("cancelled")}> cancelled : {item?.cancelled}</span>}
              </div>
            </>
          )
        },
      } : { label: "HIDE" },
  ];

  const handleApplyFilter = () => {
    setOpenDrawer(false)
    setIsApply(true)
    setFirstDriver(isFirstDriver)
    setShiftStatus(isShiftStatus)
    setOffBoardStatus(isOffBoardStatus)
    setBackgroundCheck(isBackgroundCheck)
    setShowColumn(columndata)
    setCoaching(isCoaching)
    setBtReview(isBtReview)
    setExperied(isExpired)
    actions.refetch.setIsDataRefetch(true);
  }

  const handleState = () => {
    setMetro([])
    setFromScoreRange("")
    setToScoreRange("")
    setFromShiftRange("")
    setToShiftRange("")
    setIsFirstDriver(false);
    setIsShiftStatus(false);
    setIsOffBoardStatus(false);
    setIsBackgroundCheck(false)
    setBackgroundCheck(false)
    setFirstDriver(false)
    setShiftStatus(false)
    setOffBoardStatus(false)
    setCoaching(false)
    setBtReview(false)
    setIsCoaching(false)
    setIsBtReview(false)
    setExperied(false)
    setIsExperied(false)
    setSelectState([])
    setStatusFilter([])
    setFromDate("")
    setToDate("")
    setOnboardingFrom("");
    setOnboardingTo("");
    setOffboardedFrom("");
    setOffboardedTo("");
    setIntrestDriversFromDate("");
    setIntrestDriversToDate("");
    setSaveData("")
    setColumnData(data)
    setShowColumn(data)
    setHideColumn(defaultHideDriverColumn)
    setCompanyData([])
    setDays([])
    setTime([])
    setTimeAmount("")
    setExpiredFromDate("")
    setExpiredToDate("")
  }

  const handleReset = () => {
    setFilteredDrivers([]);
    setOpenDrawer(false)
    setIsApply(false)
    handleState()
    setHideColumn(defaultHideDriverColumn)
    setShowColumn(hideDriverColumn)
    actions.refetch.setIsDataRefetch(true);
  }

  const handleCloseDrawer = () => {
    if (isApply) {
      setOpenDrawer(false)
    } else {
      setOpenDrawer(false)
      handleState()
    }
  }

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">
            <HeaderTextLayout
              headerText="Driver List"
              className="col-lg-3 col-md-3 main__driver"
              data={
                filteredDrivers && filteredDrivers.length > 0
                  ? `${filteredDrivers.length} Drivers on Screen`
                  : ""
              }
              dataLength={
                Array.isArray(filteredDrivers) ? filteredDrivers.length : "0"
              }
              totalLength={isTotal && isTotal > 0 ? (filteredDrivers.length > 0 && filteredDrivers.length < 10) ? `(${"0".concat(isTotal)} Found)` : `(${isTotal} Found)` : ""}
            >
              <div className="col-lg-9 col-md-9 col-sm-12 filter">
                <div className="filter__inner p-0 justify-content-end">
                  <div className="filter__ctrls-holder filter__input-holder d-flex justify-content-end search__container">
                    <div className="searchbar__container">
                      <input
                        className="form__input customized__input"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Quick Search"
                        onKeyDown={handleKeyDown}
                        disabled={disable}
                      />
                      {searchQuery?.length > 0 && (
                        <ClearIcon
                          className="clear__btn filter__search-icon"
                          onClick={handleClearSearch}
                          style={{
                            right: "45px",
                            top: "12px",
                          }}
                        />
                      )}
                      <button
                        disabled={searchQuery?.length <= 0}
                        className="search__button"
                        onClick={() => handleSearchingDriver()}
                      >
                        <IoSearchSharp />
                      </button>
                    </div>
                  </div>

                  <ReactSelect
                    options={getsavedData?.map((item) => {
                      return {
                        value: item?.filtername,
                        label: item?.filtername,
                      };
                    })}
                    isClearable={true}
                    onChange={(item) => {
                      onSaveDataChange(item)
                      actions.refetch.setIsDataRefetch(true);
                    }}
                    placeholder="Saved Filter"
                    value={saveData}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={styles}
                  />

                  <button className="refresh_btn filter_btn"
                    disabled={disable}
                    onClick={() => setOpenDrawer(true)}
                  >
                    <img src={filterLogo} alt="" className="filterit" />
                  </button>

                  <div>
                    <button
                      id="fade-button"
                      className="btn btn_block btn_accent btn_export_15"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget)
                        setIsExport(true)
                      }}
                      variant="contained"
                      disabled={
                        loadingCsv || filteredDrivers?.length === 0
                          ? true
                          : false
                      }
                    >
                      {loadingCsv ? (
                        <div className="loading" style={{ color: "white" }}>
                          <span className="loading__text" style={{ color: "white" }}>
                            Exporting
                          </span>
                          ...
                        </div>
                      ) : (
                        <> <span> Export <KeyboardArrowDownIcon className="mr-0" /></span> </>
                      )}
                    </button>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        'aria-labelledby': 'fade-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClick={() => setAnchorEl(null)}
                      TransitionComponent={Fade}
                    >
                      {exportMenu.map(({ value, name }) => (
                        <MenuItem key={name} value={name} onClick={() => exportData(value)} style={{ width: "100px" }}>
                          {name}
                        </MenuItem>

                      ))}
                    </Menu>
                  </div>
                  
                  <button className="refresh_btn" onClick={() => actions.refetch.setIsDataRefetch(true)}>
                    <Refresh className="refresh_icon" />
                  </button>

                  {csvData ? (
                    <CSVLink
                      data={csvData.data}
                      headers={csvData.headers}
                      filename={csvData.filename}
                      ref={csvInstance}
                    />
                  ) : undefined}

                </div>
              </div>
            </HeaderTextLayout>

            <InfiniteScroll
              className="infinit_table"
              dataLength={filteredDrivers?.length}
              next={fetchMoreDrivers}
              hasMore={hasMore}
              style={{
                paddingBottom: !hasMore ? "0px" : "10px",
              }}
              loader={
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height:
                      filteredDrivers && filteredDrivers?.length <= 0
                        ? "70vh"
                        : "auto",
                  }}
                >
                  <CircularProgress className="loading-progress" />
                </div>
              }
              height={"80vh"}
              endMessage={
                filteredDrivers &&
                filteredDrivers?.length <= 0 && (
                  <div
                    style={{
                      height: "70vh",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <p>
                      <b>No Result Found</b>
                    </p>
                  </div>
                )
              }
            >
              {isLoading ? (
                <div className="loading-holder">
                  <CircularProgress className="loading-progress" />
                </div>
              ) : (
                <Table
                  columns={columns}
                  items={filteredDrivers}
                  className="table_drivers"
                  rowClass={(item) =>
                    cn({
                      "table__tr-danger":
                        item.onboardingLicenceFillStatus !== "completed",
                    })
                  }
                  activeItem={activeItem}
                  width={"100%"}
                />
              )}
            </InfiniteScroll>

            <CustomDrawer
              openDrawer={openDrawer}
              handleCloseDrawer={handleCloseDrawer}
              handleApplyFilter={handleApplyFilter}
              handleReset={handleReset}
              handleCustomRange={handleCustomRange}
              handleTimeRangeSelection={handleTimeRangeSelection}
              onBoardingRange={onBoardingRange}
              handleOnboardedTimeRange={handleOnboardedTimeRange}
              offBoardingRange={offBoardingRange}
              handleOffboardedTimeRange={handleOffboardedTimeRange}
              intrestDriverRange={intrestDriverRange}
              handleIntrestDriverTimeRange={handleIntrestDriverTimeRange}
              obj={propsobj}
              onSaveDataChange={onSaveDataChange}
              expiryRange={expiryRange}
              handleExpiryTimeRange={handleExpiryTimeRange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
