import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { actions } from "../../../redux/store/store";
import { useLoginMutation } from "../../../api/auth";

import "../../../styles/_login.scss";
import brandLogo from "../../../images/logo-better-truck.svg";

export default function Login(props) {
  const { onStopUserQuery } = props;
  const location = useLocation();
  const [username, setUsername] = useState(
    (location.state && location.state.login) || ""
  );
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();

  const performLogin = async (e) => {
    e.preventDefault();

    setError("");
    const { data: user, error } = await login({
      username,
      password,
      loginType: "web",
    });
    localStorage.setItem(
      "user_metro",
      JSON.stringify(
        user?.metros.map((metro) => {
          return metro.name;
        })
      )
    );
    if (!error) {
      if (
        ["admin", "root", "bookkeeper", "metro_manager"].includes(user.role)
      ) {
        onStopUserQuery(false);
        actions.notifications.add({
          message: "Logged in",
          status: "success",
        });
        if (user?.role === "bookkeeper") navigate("/deposit");
        else navigate("/drivers");
      } else {
        setError("This user does not have admin rights");
      }
    } else {
      if (error.status === 401 || error.originalStatus === 401) {
        setError("Incorrect username or password");
      } else {
        setError((error.data && error.data.message) || "Something went wrong");
      }
    }
  };
  //   [username, password]
  // );

  // const toRegistration = (e) => {
  //   e.preventDefault();
  //   navigate("/register");
  // };

  const toForgotPassword = (e) => {
    e.preventDefault();
    navigate("/forgotPassword");
  };

  return (
    <div className="screen screen_flex">
      <div className="container container_contain-center loginmain">
        <img className="brandlogo" src={brandLogo} alt="" />
        <div className="login">
          <form>
            <div className="form__item">
              <label className="form__label" htmlFor="login">
                Email
              </label>
              <input
                id="login"
                className="form__input"
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="form__item">
              <label className="form__label" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                className="form__input" /*form__input-invalid <= class for invalid value*/
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
              />
              {/*example msg for invalid*/}
              {/*<div className="form__msg form__msg_invalid">value invalid</div>*/}
            </div>
            <div className="form__item form__item_min-margin">
              <button
                className="btn btn_block btn_accent"
                disabled={isLoading}
                onClick={performLogin}
                type="submit"
              >
                Login
              </button>
            </div>
            {error && (
              <div className="form__item form__item_min-margin">
                <div className="form__msg form__msg_summary form__msg_invalid">
                  {error}
                </div>
              </div>
            )}

            {/* <div className="login__link-holder">
              <a href="/register" onClick={toRegistration}>Register</a>
            </div>
             */}

            <div className="login__link-holder">
              <a href="/forgotPassword" onClick={toForgotPassword}>
                Forgot password ?
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
