export const multiSelectStyle = (width) => {
  return {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4195F5" : "white",
      paddingTop: "3px",
      paddingBottom: "0px",
      "&:hover": {
        background: "#4195F5",
        color: "#FFFFFF",
        transition: "all 0.8s",
      },
    }),
    control: (styles) => ({
      ...styles,
      borderRadius: "2px",
      padding: "3px 0",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#e3eaf3",
      },
      borderColor: "#e3eaf3",

      width: width || "33rem",
      height: "2.8rem",
      overflowY: "auto",
      padding: 0
    }),
    menu: (styles) => ({
      ...styles,
      // overflow: "hidden",
      borderRadius: "0.50rem",
      width: "33rem",
      // top: "85%",
      // right:"4%",
      left: "2%",
      fontSize: "18px",
      // height: "auto",
      paddingTop: "10px",
      paddingBottom: "10px",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.24)",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "0.9rem",
    }),
    multiValue: (styles) => ({
      ...styles,
      maxWidth: "120px"
    }),
  }
};

export const orderReactSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#4195F5" : "white",
    paddingTop: "3px",
    paddingBottom: "0px",
    "&:hover": {
      background: "#4195F5",
      color: "#FFFFFF",
      transition: "all 0.8s",
    },
  }),
  control: (styles) => ({
    ...styles,
    borderRadius: "5px",
    padding: "3px 0",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#dadada",
    },
    border: "2px solid #dadada !important",
    borderRight: "none !important",
    borderTopRightRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
    width: "175px",
    height: "2.7rem",
    overflowY: "auto",
    padding: 0
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: "0.50rem",
    width: "33rem",
    width: "14rem",
    left: "2%",
    paddingTop: "10px",
    paddingBottom: "10px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.24)",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "13px",
  }),
  multiValue: (styles) => ({
    ...styles,
    maxWidth: "120px",
    fontSize: "13px",
  }),
}

export const filterReactSelectStyle = (width) => {
  return {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4195F5" : "white",
      paddingTop: "3px",
      paddingBottom: "0px",
      "&:hover": {
        background: "#4195F5",
        color: "#FFFFFF",
        transition: "all 0.8s",
      },
    }),
    control: (styles) => ({
      ...styles,
      borderRadius: "2px",
      padding: "3px 0",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#e3eaf3",
      },
      borderColor: "#e3eaf3",
      padding: "3px 0",
    }),
    menu: (styles) => ({
      ...styles,
      borderRadius: "0.50rem",
      fontSize: "18px",
      paddingTop: "10px",
      paddingBottom: "10px",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.24)",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "0.9rem",
    }),
    multiValue: (styles) => ({
      ...styles,
      maxWidth: "120px"
    }),
  }
}
