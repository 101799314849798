import _ from "lodash";
import cn from "classnames";
import { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaCheck, FaFileUpload } from "react-icons/fa";
import { TiWarning } from "react-icons/ti";
import { Modal, CircularProgress } from "@mui/material";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";

import { fetchBlob, uploadFile } from "../../../../util/fetch";
import History from "./History";
import {
  useGetDriverQuery,
  useUpdateDriverMutation,
  useCreateOrUpdateCustomerMutation,
  useCreateOrUpdateFundingSourceMutation,
  useApproveDriverMutation,
  useBackgroundCheckMutation,
} from "../../../../api/drivers";
import fields from "./fields";
import { actions } from "../../../../redux/store/store";
import Time from "../../DaySchedule";
import { InputItem, ErrorMessage } from "../../Form";
import { maskingInfo } from "../../../shared/helper/index";
import EditMetro from "./editMetro";

import "../style.scss";
import "./style.driver.scss";
import React from "react";
import { sendSuccessMessage } from "../../../../constants/extras/sendSuccessMsg";
import {
  checkForError,
  sendErrorMessage,
} from "../../../../constants/extras/errorHandlers";
import EditNotes from "./editNotes";
import { handleBackgroundStatusesBadge } from "../../../../constants/extras/statusesBadge";
import EditCompany from "./editCompany";
import { useChangeRoleMutation } from "../../../../api/admins";
import SwitchRoleConfirmation from "../../../common/SwitchRoleConfirmation";
import EditPhone from "./editPhone";
import EditSchedule from "./editSchedule";
import EditDriverLicense from "./editDriverLicense";
import EditInsuracePolicy from "./editInsurancePolicy";

const emptyImg =
  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};


const CustomModal = ({ id }) => {
  const { data: driver, isFetching } = useGetDriverQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });
  const [update, updateReq] = useUpdateDriverMutation();
  const [createOrUpdateCustomer, createOrUpdateCustomerReq] =
    useCreateOrUpdateCustomerMutation();
  const [createOrUpdateFundingSource, createOrUpdateFundingSourceReq] =
    useCreateOrUpdateFundingSourceMutation();
  const [approveDriver, approveDriverReq] = useApproveDriverMutation();
  const [backgroundCheck] = useBackgroundCheckMutation();

  const customerForm = useForm();
  const fundingSourceForm = useForm();

  const limitMsg = "File size must be less than 10 MB"

  const [insuranceImg, setInsuranceImg] = useState(emptyImg);
  const [licenseFrontImg, setLicenseFrontImg] = useState(emptyImg);
  const [licenseBackImg, setLicenseBackImg] = useState(emptyImg);
  const [profileImg, setProfileImg] = useState(emptyImg);
  const [switchRole, setSwitchRole] = useState([false, ""]);
  const [isImageChange, setIsImageChange] = useState("");
  const [loading, setLoading] = useState(false)
  const [imgLimit, setImgLimit] = useState({
    front: false,
    back: false,
    file: false,
    profilePhoto: false,
    frontMsg: limitMsg,
    backMsg: limitMsg,
    fileMsg: limitMsg,
    profileMsg: limitMsg,
  })

  const currentUser = useSelector((state) => state.auth.currentUser);

  let driverData;
  if (driver !== undefined) {
    driverData = { ...driver };
  }

  const checkForRoot = currentUser && currentUser?.role === "root";
  // const checkForIsDriverBlocked = driver && driver?.isBlocked === true;
  const checkForApproveOrReject =
    driver && driver?.status === "not_approved" && checkForRoot;
  const checkForOffBoardDriver =
    driver?.status === "approved" || driver?.status === "inactive";
  const checkForOnBoardDriver = driver?.status === "offboarded";
  const checkForMakingDriverInactive = driver?.status === "approved";
  const checkForActivateDrivers = driver?.status === "inactive";

  const fetchImages = useCallback(async () => {
    setLoading(true)
    const debugInsurance = await fetchBlob(`drivers/${id}/debugInsurance`);
    const frontLicense = await fetchBlob(`drivers/${id}/debugLicense/front`);
    const backLicense = await fetchBlob(`drivers/${id}/debugLicense/back`);
    const profilePhoto = await fetchBlob(`drivers/${id}/getProfilePhoto`);
    setInsuranceImg(debugInsurance);
    setLicenseFrontImg(frontLicense);
    setLicenseBackImg(backLicense);
    setProfileImg(profilePhoto);
    setLoading(false)
  }, [id]);

  useEffect(() => fetchImages(), [fetchImages]);

  const fileUpload = (type, url) => async (e) => {
    if (e.target.files[0] === "") return;
    e.preventDefault();
    const imageType = e.target.files[0]?.type.includes("image")
    if (e.target.files[0]?.size < 10 * 1024 * 1024 && imageType) {
      setIsImageChange(type)
      setImgLimit({ ...imgLimit, [type]: false, [`${type}Msg`]: imageType && limitMsg })
      const res = await uploadFile(`/drivers/${id}/${url}`, type, e.target.files[0]);

      if (res?.error) {
        setIsImageChange("")
        sendErrorMessage(`${res?.message}`);
      } else {
        const debugInsurance = type === "file" && await fetchBlob(`drivers/${id}/debugInsurance`);
        const frontLicense = type === "front" && await fetchBlob(`drivers/${id}/debugLicense/front`);
        const backLicense = type === "back" && await fetchBlob(`drivers/${id}/debugLicense/back`);
        const profilePhoto = type === "profilePhoto" && await fetchBlob(`drivers/${id}/getProfilePhoto`);
        type === "file" && setInsuranceImg(debugInsurance);
        type === "front" && setLicenseFrontImg(frontLicense);
        type === "back" && setLicenseBackImg(backLicense);
        type === "profilePhoto" && setProfileImg(profilePhoto);
        setIsImageChange("")
        checkForUpdateStatus(type)
      }
      return res
    } else {
      setImgLimit({
        ...imgLimit, [type]: true,
        [`${type}Msg`]: imageType ? limitMsg : "Only images are allowed"
      })
    }
  };

  const checkForUpdateStatus = async (type) => {
    let { firstName,lastName,address,city,state,zipCode,insuranceDateExp,insurancePolicyNumber,insuranceState,driverLicenseBirthday,driverLicenseDateExp,driverLicenseNumber,driverLicenseState,driverLicenseBack,driverLicenseFront } = driverData;
    let params = {};
    if(type === 'profilePhoto' && ![firstName,lastName,address,city,state,zipCode].includes('') && driverData?.onboardingDriverBadgeFillStatus !== 'completed'){
      params.onboardingDriverBadgeFillStatus = 'completed'
    }
    if(type === 'file' && ![insuranceDateExp,insurancePolicyNumber,insuranceState].includes('') && driverData?.onboardingInsuranceFillStatus !== 'completed'){
      params.onboardingInsuranceFillStatus = 'completed'
    }
    if(type === 'front' && ![driverLicenseBirthday,driverLicenseDateExp,driverLicenseNumber,driverLicenseState].includes('') && driverLicenseBack && driverData?.onboardingLicenceFillStatus !== 'completed'){
      params.onboardingLicenceFillStatus = 'completed'
    }
    if(type === 'back' && ![driverLicenseBirthday,driverLicenseDateExp,driverLicenseNumber,driverLicenseState].includes('') && driverLicenseFront && driverData?.onboardingLicenceFillStatus !== 'completed'){
      params.onboardingLicenceFillStatus = 'completed'
    }
    if(Object.keys(params).length > 0){
      await update({_id: id, ...params});
      refreshData();
    }

  }

  //const for demo
  const [isFileUploadBack_error, setIsFileUploadBack_error] = useState(false);

  const [showLicense, setShowLicense] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [changeRole, { isLoading }] = useChangeRoleMutation();

  const classNameNormalize = (name) => {
    return name
      ? name
        .toLowerCase()
        .replaceAll(" ", "-")
        .replaceAll("/", "-")
        .replaceAll(":", "")
      : null;
  };

  if (driverData) {
    driverData.EIN = maskingInfo(driverData.EIN);
    driverData.SSN = maskingInfo(driverData.SSN);
    driverData.orginalDriverLicenseNumber = driverData?.driverLicenseNumber ? JSON.parse(JSON.stringify(driverData?.driverLicenseNumber)) : '';
    driverData.driverLicenseNumber = maskingInfo(
      driverData.driverLicenseNumber
    );
    driverData.phone = formatPhoneNumber(driverData.phone);
  }
  const BriefItem = useCallback(
    ({ width, field, title = _.startCase(field), edit }) => {
      const output =
        field instanceof Function
          ? field(driverData)
          : _.get(driverData, field);

      return (
        <li className={`brief__item brief__item_${classNameNormalize(title)}`}>
          <span
            className="brief__item-cell brief__item-cell_title"
            style={{
              width: width ? `${width}%` : "30%",
            }}
          >
            {title}:
          </span>
          <span
            className="brief__item-cell brief__item-cell_value"
            style={{ width: "50%" }}
          >
            {(() => {
              switch (title) {
                case "Birthday":
                  return (
                    <EditDriverLicense
                      id={id}
                      currentUser={currentUser && currentUser}
                      title={'Update Driver License'}
                      driverData={driverData}
                      birthday={driverData.driverLicenseBirthday}
                    />
                  );
                case "Date Expiration":
                  if(field === 'insuranceDateExp'){
                    return (
                      <EditInsuracePolicy
                        id={id}
                        currentUser={currentUser && currentUser}
                        title={'Update Insurance Policy'}
                        driverData={driverData}
                        insuranceDateExp={driverData.insuranceDateExp}
                      />
                    );
                  } else {
                    return <React.Fragment>{output}</React.Fragment>;
                  }
                case "Phone":
                  return (
                    <EditPhone
                      notes={output}
                      id={id}
                      currentUser={currentUser && currentUser}
                      title={title}
                    />
                  );
                case "Days":
                  return (
                    <React.Fragment>
                      {/* <Days {...driver} /> */}
                      <EditSchedule
                        driver={driver}
                        currentUser={currentUser && currentUser}
                        id={id}
                      />
                    </React.Fragment>
                  );
                case "Time":
                  return <Time {...driver} />;
                case "Last Login at":
                  return driver?.lastLoginTime
                    ? moment(driver?.lastLoginTime).format("LLL")
                    : "";
                case "Metro":
                  return (
                    <EditMetro
                      metro={output}
                      id={id}
                      currentUser={currentUser && currentUser}
                      title={title}
                    />
                  );
                case "Notes":
                  return (
                    <EditNotes
                      notes={driverData?.internalNote}
                      id={id}
                      currentUser={currentUser && currentUser}
                      title={title}
                    />
                  );
                case "Company":
                  return (
                    <EditCompany
                      company={driver?.company}
                      id={id}
                      currentUser={currentUser && currentUser}
                      title={title}
                      edit={edit}
                    />
                  );
                case "First Name":
                  return (
                    <EditNotes
                      notes={driver?.firstName}
                      id={id}
                      currentUser={currentUser && currentUser}
                      title={title}
                    />
                  );
                case "Last Name":
                  return (
                    <EditNotes
                      notes={driver?.lastName}
                      id={id}
                      currentUser={currentUser && currentUser}
                      title={title}
                    />
                  );
                case "Address":
                  return (
                    <EditNotes
                      notes={driver?.address}
                      id={id}
                      currentUser={currentUser && currentUser}
                      title={title}
                    />
                  );
                case "City":
                  return (
                    <EditNotes
                      notes={driver?.city}
                      id={id}
                      currentUser={currentUser && currentUser}
                      title={title}
                    />
                  );
                case "State":
                    return (
                      <EditCompany
                        company={output}
                        id={id}
                        currentUser={currentUser && currentUser}
                        title={title}
                        edit={edit}
                      />
                    );
                case "Zip Code":
                  return (
                    <EditNotes
                      notes={driver?.zipCode}
                      id={id}
                      state={driver}
                      currentUser={currentUser && currentUser}
                      title={title}
                    />
                  );
                case "1099 Consent":
                  return driverData?.isConsent1099 ? "Enabled" : "Disabled";
                case "Consent Time":
                  return driver?.consentTime
                    ? moment(driver?.consentTime).format("LLL")
                    : "";
                default:
                  return <React.Fragment>{output}</React.Fragment>;
              }
            })()}
          </span>
        </li>
      );
    },
    [driver, driverData, currentUser, id]
  );

  const BriefItems = ({ fields, className, mainTitle, width, edit }) => {
    return (
      <ul
        className={`brief brief_${classNameNormalize(className)}`}
        style={{ width: "100%" }}
      >
        {fields.map((field, index) => {
          const props = field instanceof Object ? field : { field };
          return (
            <BriefItem
              key={index}
              {...props}
              mainTitle={mainTitle}
              width={width}
              edit={edit}
            />
          );
        })}
      </ul>
    );
  };

  const Brief = ({ fields, title, long, width, edit }) => {
    return (
      <div className="form__col form__col_height-flex">
        <div className="section">
          <h3 className={cn("section__title", { section__title_long: long })}>
            {title}:
          </h3>
          <BriefItems
            fields={fields}
            className={title}
            mainTitle={title}
            width={width}
            edit={edit}
          />
          {title === "Primary" && currentUser?.role === "root" && (
            <div style={{ position: 'relative' }}>
              <button
                className="btn btn_accent customized-details-btn driver-operator"
                onClick={() => setSwitchRole([true, id])}
              >
                Change driver to operator
              </button>
              {isLoading && <CircularProgress size={18}
                sx={{
                  color: '#4195f5',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-8px',
                  marginLeft: '-10px',
                }} />}
            </div>
          )}
        </div>
      </div>
    );
  };

  const refreshData = () => {
    actions.refetch.setIsDataRefetch(true);
  };
  const handleStatus = (result, status, message) => {
    if (
      ["blocked", "unblocked"].includes(status)
        ? status === "blocked"
          ? result?.data?.isBlocked
          : !result?.data?.isBlocked
        : status === "onboard"
          ? result?.data?.status === "approved"
          : result?.data?.status === status
    ) {
      sendSuccessMessage(
        message
          ? message
          : `Driver ${status === "onboard" ? "onboarded" : status} successfully`
      );
      refreshData();
    } else {
      checkForError(result?.data?.error || result?.error || result);
    }
  };

  const handleSwitchRole = async (id) => {
    let response = await changeRole({
      id: id,
      role: "operator",
    });
    if (response && response?.data?.role) {
      sendSuccessMessage(`${response.data.role} role assigned successfully`);
      actions.modal.closeDriver();
      actions.refetch.setIsDataRefetch(true);
      setSwitchRole([false, ""]);
    } else {
      sendErrorMessage(`${response.data.message}`);
      setSwitchRole([false, ""]);
    }
  };

  const handleReasonUI = () => {
    if (
      driver?.offboardedReason ||
      (driver?.inactivatedReason && driver?.offboardedReason)
    ) {
      return driver?.offboardedReason;
    } else if (driver?.inactivatedReason) {
      return driver?.inactivatedReason;
    } else {
      return false;
    }
  };

  const ImageLoader = ({ type }) => {
    return (
      <div style={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <React.Fragment>
      <SwitchRoleConfirmation
        switchRole={switchRole}
        setSwitchRole={setSwitchRole}
        handleSwitchRole={handleSwitchRole}
        name={"operator"}
        title={"Change driver to operator"}
      />
      <Modal
        open
        onClose={() => actions.modal.closeDriver()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className={`modal modal_wide ${!isFetching ? "after-loading" : "when-loading"
            }`}
        >
          <div className="modal__header">
            <h2 className="modal__title">
              {driver && driver?.username ? driver?.username : ""}
            </h2>
          </div>
          <div className="modal__body">
            {!isFetching ? (
              <div className="form__row">
                {(driver?.inactivatedReason || driver?.offboardedReason) && (
                  <div
                    className="form__row section reason__container"
                    style={{
                      gap: "unset",
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <h3
                      className={cn("section__title reason__container__title")}
                    >
                      Reason:
                    </h3>
                    {handleReasonUI() && (
                      <p className="reason__container__reason">
                        {handleReasonUI() ? handleReasonUI() : ""}
                      </p>
                    )}
                  </div>
                )}
                <div className="form__row">
                  <Brief title="Primary" fields={fields.primary} edit={true} />
                  <div className="form__col">
                    <div className="section">
                      <h3 className="section__title">Personal:</h3>
                      <div className="brief__union">
                        <BriefItems fields={fields.personal} edit={true} />
                      </div>

                      <div className="license">
                        <button
                          className="btn license__btn-show-switch"
                          onClick={() => setShowProfile(!showProfile)}
                        >
                          {showProfile ? (
                            <React.Fragment>
                              Hide Profile Photo
                              <IoIosArrowUp className="btn__icon btn__icon_right" />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              Show Profile Photo
                              <IoIosArrowDown className="btn__icon btn__icon_right" />
                            </React.Fragment>
                          )}
                        </button>
                      </div>
                      {showProfile && (
                        <div className="license__item section">
                          <div style={{ position: "relative" }}>
                            {
                              loading || isImageChange === "profilePhoto" ? 
                              (
                                <ImageLoader type="profilePhoto" />
                              ) :
                              <PinchZoomPan maxScale={"10"}>
                                <div style={{ width: "100%" }}>
                                  <img
                                    alt="Test"
                                    src={profileImg}
                                    style={{
                                      width: "90%",
                                      maxHeight: "250px",
                                      display: "block",
                                      margin: "auto",
                                    }}
                                  />
                                </div>
                              </PinchZoomPan>
                            }
                          </div>
                          <div className="file-upload">
                                <label
                                  className={`file-upload__btn`}
                                >
                                  <input
                                    type="file"
                                    onChange={fileUpload(
                                      "profilePhoto",
                                      "uploadProfilePhoto"
                                    )}
                                    accept="image/*"
                                  />
                                  <span className="btn btn_accent">
                                    <FaFileUpload className="btn__icon" />
                                    Choose File
                                  </span>
                                  <div className={`form__msg form__msg_warning`} style={{ color: !imgLimit.profilePhoto && "grey" }}>
                                    <span>
                                      {imgLimit?.profileMsg}
                                    </span>
                                  </div>
                                </label>
                              </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form__row form__col_full-width">
                  <Brief
                    title="Time/Schedule"
                    fields={fields.timeSchedule}
                    className="brief_time-schedule"
                  />
                  <Brief
                    title="Device Info"
                    fields={fields.deviceInfo}
                    width={1}
                  />
                </div>

                <Brief
                  title="Car/Vehicle"
                  fields={fields.carVehicle}
                  width={18}
                />

                <Brief
                  title="Onboarding status"
                  fields={fields.onboarding}
                  width={50}
                />

                <div className="form__col form__col_full-width">
                  <div className="section section_group">
                    <h3 className="section__title">Tax Information:</h3>
                    <div className="form__row">
                      <Brief title="Basic" fields={fields.basic} width={1} />

                      <Brief
                        title="Signature"
                        fields={fields.signature}
                        width={62}
                      />

                      <Brief
                        title="Tax Identify Information"
                        fields={fields.tax}
                        width={1}
                      />

                      <Brief
                        long
                        title="Request for Taxpayer Identification Number and Certification"
                        fields={fields.taxpayerRequest}
                        width={65}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__col form__col_full-width">
                  <div className="section">
                    <h3 className="section__title">Driver License:</h3>

                    {/*maybe must union driverLicense1 and driverLicense2 to one object?*/}
                    <div className="brief__union">
                      <BriefItems fields={fields.driverLicense1} />
                      <BriefItems fields={fields.driverLicense2} edit={false} />
                    </div>

                    <div className="license">
                      <button
                        className="btn license__btn-show-switch"
                        onClick={() => setShowLicense(!showLicense)}
                      >
                        {showLicense ? (
                          <React.Fragment>
                            Hide license{" "}
                            <IoIosArrowUp className="btn__icon btn__icon_right" />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            Show license{" "}
                            <IoIosArrowDown className="btn__icon btn__icon_right" />
                          </React.Fragment>
                        )}
                      </button>

                      {showLicense && (
                        <div className="license__body">
                          <div className="license__items-holder">
                            <div className="license__item section">
                              <h4 className="section__title">Front:</h4>

                              <div style={{ position: "relative" }}>
                                {
                                  loading || isImageChange === "front" ?
                                    (
                                      <ImageLoader type="front" />
                                    ) :
                                    <PinchZoomPan maxScale={"10"}>
                                      <div style={{ width: "100%" }}>
                                        <img
                                          alt="Test"
                                          src={licenseFrontImg}
                                          className="responsive"
                                        />
                                      </div>
                                    </PinchZoomPan>
                                }
                              </div>
                              {/* <img src={licenseFrontImg}/> */}

                              <div className="file-upload">
                                <label
                                  className={`file-upload__btn`}
                                >
                                  <input
                                    type="file"
                                    onChange={fileUpload(
                                      "front",
                                      "uploadLicense"
                                    )}
                                    accept="image/*"
                                  />
                                  <span className="btn btn_accent">
                                    <FaFileUpload className="btn__icon" />
                                    Choose File
                                  </span>
                                  <div className={`form__msg form__msg_warning`} style={{ color: !imgLimit.front && "grey" }}>
                                    <span>
                                      {imgLimit?.frontMsg}
                                    </span>
                                  </div>
                                </label>
                              </div>
                            </div>

                            <div className="license__item section">
                              <h4 className="section__title">Back:</h4>
                              <div style={{ position: "relative" }}>
                                {
                                  loading || isImageChange === "back" ?
                                    (
                                      <ImageLoader type="back" />
                                    ) :
                                    <PinchZoomPan maxScale={"10"}>
                                      <div style={{ width: "100%" }}>
                                        <img
                                          alt="Test"
                                          src={licenseBackImg}
                                          className="responsive"
                                        />
                                      </div>
                                    </PinchZoomPan>
                                }
                              </div>
                              {/* <img src={licenseBackImg}/> */}

                              <div className="file-upload">
                                <label
                                  className={`file-upload__btn`}
                                >
                                  <input
                                    type="file"
                                    onChange={fileUpload(
                                      "back",
                                      "uploadLicense"
                                    )}
                                    accept="image/*"
                                  />
                                  <span className="btn btn_accent">
                                    <FaFileUpload className="btn__icon" />
                                    Choose File
                                  </span>
                                  <div className={`form__msg form__msg_warning`} style={{ color: !imgLimit.back && "grey" }}>
                                    <span>
                                      {imgLimit?.backMsg}
                                    </span>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>

                          {!["completed", "rejected"].includes(
                            driver.onboardingLicenceFillStatus
                          ) && (
                              <div className="btn-bar">
                                <div style={{ position: 'relative' }}>
                                  <button
                                    className={cn("btn", {
                                      btn_disabled: updateReq.isLoading,
                                    })}
                                    onClick={() =>
                                      update({
                                        _id: driver._id,
                                        onboardingLicenceFillStatus: "rejected",
                                      })
                                    }
                                  >
                                    Reject
                                    {updateReq.isLoading && <div style={{ width: '25px' }}></div>}
                                  </button>
                                  {updateReq.isLoading && (
                                    <CircularProgress size={18}
                                      sx={{
                                        color: '#fff',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '83%',
                                        marginTop: '-8px',
                                        marginLeft: '-10px',
                                      }} />
                                  )}
                                </div>
                                <div style={{ position: 'relative' }}>
                                  {updateReq.isLoading &&
                                    <CircularProgress size={18}
                                      sx={{
                                        color: '#fff',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '25%',
                                        marginTop: '-8px',
                                        marginLeft: '-10px',
                                        zIndex: '9'
                                      }} />
                                  }
                                  <button
                                    className={cn("btn btn_accent", {
                                      btn_disabled: updateReq.isLoading,
                                    })}
                                    onClick={() =>
                                      update({
                                        _id: driver._id,
                                        onboardingLicenceFillStatus: "completed",
                                      })
                                    }
                                  >
                                    {!updateReq.isLoading ?
                                      (<FaCheck className="btn__icon" />) :
                                      <div style={{ width: '25%' }}></div>}
                                    Approve
                                  </button>
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form__col form__col_full-width">
                  <div className="section">
                    <h3 className="section__title">Insurance Policy:</h3>
                    <div className="brief__union">
                      <BriefItems fields={fields.insurancePolicy} />
                    </div>

                    <div className="license">
                      <button
                        className="btn license__btn-show-switch"
                        onClick={() => setShowPolicy(!showPolicy)}
                      >
                        {showPolicy ? (
                          <React.Fragment>
                            Hide Policy
                            <IoIosArrowUp className="btn__icon btn__icon_right" />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            Show Policy
                            <IoIosArrowDown className="btn__icon btn__icon_right" />
                          </React.Fragment>
                        )}
                      </button>
                      {showPolicy && (
                        <div className="license__body">
                          <div className="license__items-holder">
                            <div className="license__item section">
                              <div style={{ position: "relative" }}>
                                {
                                  loading || isImageChange === "file" ?
                                    (
                                      <ImageLoader type="file" />
                                    ) :
                                    <PinchZoomPan maxScale={10}>
                                      <div style={{ width: "100%" }}>
                                        <img
                                          alt="Test"
                                          src={insuranceImg}
                                          className="responsive"
                                          style={{
                                            objectFit: "contain",
                                            width: "50%",
                                            textAlign: "center",
                                          }}
                                        />
                                      </div>
                                    </PinchZoomPan>
                                }
                              </div>
                              <div className="file-upload">
                                <label
                                  className={`file-upload__btn`}
                                >
                                  <input
                                    type="file"
                                    onChange={fileUpload(
                                      "file",
                                      "uploadInsurance"
                                    )}
                                    accept="image/*"
                                  />
                                  <span className="btn btn_accent">
                                    <FaFileUpload className="btn__icon" />
                                    Choose File
                                  </span>
                                  <div className={`form__msg form__msg_warning`} style={{ color: !imgLimit.file && "grey" }}>
                                    <span>
                                      {imgLimit?.fileMsg}
                                    </span>
                                  </div>
                                </label>

                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="section" style={{ width: "49%" }}>
                  <h3 className="section__title">Background</h3>
                  <ul className="brief brief_null" >
                    <li className="brief__item brief__item_has-been convicted" >
                      <span className="brief__item-cell brief__item-cell_title">
                        Background Status:
                      </span>
                      <span
                        style={handleBackgroundStatusesBadge(
                          driver?.checkrBackgroundStatus
                        )}
                      >
                        {driver?.checkrBackgroundStatus}
                      </span>
                      {!driver?.consents?.consentId && !driver?.checkrBackgroundStatus && <React.Fragment>No Consent</React.Fragment>}
                      {driver?.consents?.consentId && !driver?.checkrBackgroundStatus && <React.Fragment>Consent Given</React.Fragment>}
                      {/* {!driver?.checkrBackgroundStatus && (
                        <button
                          className="btn btn_accent customized-details-btn"
                          type="button"
                          onClick={async () => {
                            const response = await backgroundCheck({
                              _id: driver._id,
                            });
                            if (response?.data?.status != 200) {
                              checkForError(response);
                            }
                          }}
                        >
                          Check
                        </button>
                      )} */}
                    </li>
                  </ul>

                  {driver?.btReview &&
                    <div style={{ marginLeft: "0.3rem" }}>
                      <div style={{ display: "flex", gap: "5px", marginTop: "0.2rem" }}>
                        <span style={{ fontWeight: "600", width: "22%" }}>BT Review :</span>
                        {!driver?.btReview?.isVerified ? (
                          <div style={{ width: "78%" }} >
                            <button style={{ minHeight: "0.2rem", fontSize: "13px", padding: "1.5px 4px" }} className="btn btn_accent"
                              onClick={() => {
                                actions.modal.openReason({
                                  obj: driver,
                                  title: "BT__REVIEW",
                                });
                              }}>
                              Verify
                            </button>
                          </div>)
                          : (
                            <div style={{ width: "78%" }}>
                              <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                {"Verify by "} {driver?.btReview?.userId?.username} {`(${moment(driver?.btReview?.date).format("MM/DD/YYYY hh:mm A")})`}
                              </span>
                            </div>
                          )
                        }
                      </div>
                      {driver?.btReview?.isVerified && <span style={{ marginTop: "0.2rem" }}>
                        <p style={{ marginBottom: "0px", display: "flex", alignItems: "center", gap: "5px" }}>
                          <div style={{ width: "22%", display: "flex", justifyContent: "right" }}>
                            <span style={{ fontWeight: "600", }}> Remark :  </span>
                          </div>
                          <div>
                            <span style={{ widows: "78%" }}>{`${driver?.btReview?.remark || "-"} `}</span>
                          </div>
                        </p>
                      </span>
                      }
                    </div>}

                  {driver.onboardingBackgroundFillStatus === "pending" && (
                    <div className="btn-bar" >
                      <div style={{ position: 'relative' }}>
                        {updateReq.isLoading &&
                          <CircularProgress size={18}
                            sx={{
                              color: '#fff',
                              position: 'absolute',
                              top: '50%',
                              left: '83%',
                              marginTop: '-8px',
                              marginLeft: '-10px',
                              zIndex: '9'
                            }} />
                        }
                        <button
                          className={cn("btn btn_accent", {
                            btn_disabled: updateReq.isLoading,
                          })}
                          onClick={() =>
                            update({
                              _id: driver._id,
                              onboardingBackgroundFillStatus: "completed",
                            })
                          }>
                          {updateReq.isLoading ?
                            (<FaCheck className="btn__icon" />) :
                            <div style={{ width: '25%' }}></div>}
                          Approve
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="form__col form__col_full-width_">
                  <div className="section">
                    <h3 className="section__title">Other Works:</h3>

                    <ul className="brief brief_null">
                      <li className="brief__item brief__item_has-been convicted">
                        <span className="brief__item-cell brief__item-cell_title">
                          General Warehousing:
                        </span>
                        <span className="brief__item-cell brief__item-cell_value">
                          {driver && driver.readyToGeneralWarehousing ? (
                            <React.Fragment>Yes</React.Fragment>
                          ) : (
                            <React.Fragment>No</React.Fragment>
                          )}
                        </span>
                      </li>

                      <li className="brief__item brief__item_has-previous names">
                        <span className="brief__item-cell brief__item-cell_title">
                          Package Sortation:
                        </span>
                        <span className="brief__item-cell brief__item-cell_value">
                          {driver && driver.readyToPackageSortation ? (
                            <React.Fragment>Yes</React.Fragment>
                          ) : (
                            <React.Fragment>No</React.Fragment>
                          )}
                        </span>
                      </li>

                      <li className="brief__item brief__item_send-background copy">
                        <span className="brief__item-cell brief__item-cell_title">
                          Picking / Packing / Fulfillment:
                        </span>
                        <span className="brief__item-cell brief__item-cell_value">
                          {driver && driver.readyToPickingPackingFulfillment ? (
                            <React.Fragment>Yes</React.Fragment>
                          ) : (
                            <React.Fragment>No</React.Fragment>
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                {currentUser && currentUser?.role === "root" && (

                  <>
                    {driver?.paymentmode === "BRANCH" ?
                      (
                        <div div className="form__col form__col_full-width deposit">
                          <div className="section section_group">
                            <h3 className="section__title">Deposit (BRANCH):</h3>
                            <ul className="brief brief_null" style={{ paddingTop: "0" }} >
                              <li className="brief__item brief__item_has-been convicted" >
                                <span className="brief__item-cell brief__item-cell_title">
                                  Account Number:
                                </span>
                                <span>
                                  {maskingInfo(
                                    driver.depositAccountNumber) || ""}
                                </span>
                              </li>
                              <li className="brief__item brief__item_has-been convicted" >
                                <span className="brief__item-cell brief__item-cell_title">
                                  Routing Number:
                                </span>
                                <span>
                                  {maskingInfo(driver.depositRoutingNumber) || ""}
                                </span>
                              </li>

                              <li className="brief__item brief__item_has-been convicted" >
                                <span className="brief__item-cell brief__item-cell_title">
                                  Branch Status:
                                </span>
                                <p style={{ textTransform: "capitalize" }}>
                                  {driver?.branch?.status || "-"}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>) : (
                        <div div className="form__col form__col_full-width deposit">
                          <div className="section section_group">
                            <h3 className="section__title">Deposit (DWOLLA):</h3>
                            <div className="form__row">
                              <div className="form__col form__col_height-flex">
                                <div className="section">
                                  <div className="section__title">Customer:</div>

                                  <form
                                    onSubmit={customerForm.handleSubmit(
                                      createOrUpdateCustomer
                                    )}
                                  >
                                    <InputItem
                                      name="depositHolderName"
                                      initialValue={driver.depositHolderName}
                                      title="First Name"
                                      form={customerForm}
                                    />
                                    <InputItem
                                      name="depositHolderSurname"
                                      initialValue={driver.depositHolderSurname}
                                      title="Last Name"
                                      form={customerForm}
                                    />
                                    <InputItem
                                      name="depositHolderEmail"
                                      initialValue={driver.depositHolderEmail}
                                      title="Email"
                                      form={customerForm}
                                    />

                                    <div className="form__item form__item_deposit">
                                      <input
                                        type="hidden"
                                        {...customerForm.register("_id", {
                                          value: driver._id,
                                        })}
                                      />
                                      <button
                                        className="btn btn_accent btn_block"
                                        type="submit"
                                      >
                                        Create Customer
                                      </button>
                                      {driver && !driver?.dwCustomerUrl && (
                                        <div
                                          className={`form__msg form__msg_invalid ${!driver?.dwCustomerUrl
                                            ? "form__msg_visible"
                                            : ""
                                            }`}
                                        >
                                          <span>
                                            Customer is not created in dwolla
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    <div>
                                      {JSON.stringify(createOrUpdateCustomerReq.data)}
                                    </div>
                                  </form>
                                </div>
                              </div>

                              <div
                                className="form__col form__col_height-flex"
                                style={{ paddingBottom: "0px" }}
                              >
                                <div className="section">
                                  <div className="section__title">
                                    Funding Source:
                                  </div>

                                  <form
                                    onSubmit={fundingSourceForm.handleSubmit(
                                      createOrUpdateFundingSource
                                    )}
                                  >
                                    <InputItem
                                      name="depositAccountNumber"
                                      initialValue={maskingInfo(
                                        driver.depositAccountNumber
                                      )}
                                      title="Account Number"
                                      form={fundingSourceForm}
                                    />
                                    <InputItem
                                      name="depositRoutingNumber"
                                      initialValue={maskingInfo(
                                        driver.depositRoutingNumber
                                      )}
                                      title="Routing Number"
                                      form={fundingSourceForm}
                                    />

                                    <div className="form__item">
                                      <label className="form__label">Type:</label>

                                      <div className="form__input-place">
                                        <div className="form__inline-items form__inline-items_deposit">
                                          <label
                                            htmlFor="deposit_type_checking"
                                            className="form__check-label"
                                          >
                                            <input
                                              type="radio"
                                              name="deposit_type"
                                              id="deposit_type_checking"
                                              value="checking"
                                              {...fundingSourceForm.register(
                                                "depositType",
                                                { value: driver.depositType }
                                              )}
                                            />
                                            Checking
                                          </label>
                                          <label
                                            htmlFor="deposit_type_saving"
                                            className="form__check-label"
                                          >
                                            <input
                                              type="radio"
                                              name="deposit_type"
                                              id="deposit_type_saving"
                                              value="savings"
                                              {...fundingSourceForm.register(
                                                "depositType",
                                                { value: driver.depositType }
                                              )}
                                            />
                                            Saving
                                          </label>
                                        </div>
                                      </div>
                                      <ErrorMessage />
                                    </div>

                                    <div className="form__item form__item_deposit">
                                      <input
                                        type="hidden"
                                        {...fundingSourceForm.register("_id", {
                                          value: driver._id,
                                        })}
                                      />
                                      <button
                                        className="btn btn_accent btn_block"
                                        type="submit"
                                      >
                                        Create Funding Source
                                      </button>
                                      {driver && !driver?.dwFundingSourceUrl && (
                                        <div
                                          className={`form__msg form__msg_invalid ${!driver?.dwFundingSourceUrl
                                            ? "form__msg_visible"
                                            : ""
                                            }`}
                                        >
                                          <span>
                                            Funding source is not created in dwolla
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    <div>
                                      {JSON.stringify(
                                        createOrUpdateFundingSourceReq.data
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                            <div className={`form__msg form__msg_warning`}>
                              <span>
                                *Venmo, Zelle, and PayPal accounts are not supported
                              </span>
                            </div>
                          </div>
                        </div>

                      )}
                  </>
                )}

                <History id={id} />

                <div className="form__col form__col_full-width">
                  <div className="section">
                    <ul className="brief brief_summary">
                      <li className="brief__item">
                        <span className="brief__item-cell brief__item-cell_title">
                          Wages Earned:
                        </span>
                        <span className="brief__item-cell brief__item-cell_value">
                          ${driver?.wallet?.toFixed(2)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className="loading-wrapper">
                <CircularProgress />
              </div>
            )}
          </div>
          <div className="modal__footer">
            <button
              className="btn btn_accent"
              onClick={() => actions.modal.closeDriver()}
            >
              Close
            </button>

            {checkForApproveOrReject && (
              <div style={{ position: 'relative' }}>
                {approveDriverReq.isLoading &&
                  <CircularProgress size={18}
                    sx={{
                      color: '#fff',
                      position: 'absolute',
                      top: '50%',
                      left: '83%',
                      marginTop: '-8px',
                      marginLeft: '-10px',
                    }} />
                }
                <button
                  className={cn("btn btn_accent", {
                    btn_disabled: approveDriverReq.isLoading,
                  })}
                  onClick={async () => {
                    const result = await approveDriver({ _id: driver._id });
                    handleStatus(result, "approved");
                  }}
                >
                  {!approveDriverReq.isLoading ?
                    (<FaCheck className="btn__icon" />) :
                    <div style={{ width: '25%' }}></div>
                  }
                  Approve
                </button>

              </div>
            )}
            {checkForApproveOrReject && (
              <button
                className="btn btn_reject"
                onClick={() => {
                  actions.modal.openReason({
                    obj: driver,
                    title: "REJECT__DRIVER",
                  });
                }}
              >
                Reject
              </button>
            )}

            {/* {checkForBlock && (
              <button
                className={cn("btn btn_reject", {
                  btn_disabled: blockDriverReq.isLoading,
                })}
                onClick={async () => {
                  const result = await blockDriver({ _id: driver._id });
                  handleStatus(result, "blocked");
                }}
              >
                {blockDriverReq.isLoading && (
                  <CircularProgress className="btn__progress" />
                )}
                Block
              </button>
            )}
            {checkForUnblock && (
              <button
                className={cn("btn btn_accent", {
                  btn_disabled: unblockDriverReq.isLoading,
                })}
                onClick={async () => {
                  const result = await unblockDriver({ _id: driver._id });
                  handleStatus(result, "unblocked");
                }}
              >
                {unblockDriverReq.isLoading && (
                  <CircularProgress className="btn__progress" />
                )}
                Unblock driver
              </button>
            )} */}
            {checkForActivateDrivers && (
              <div style={{ position: 'relative' }}>
                {approveDriverReq.isLoading &&
                  <CircularProgress size={18}
                    sx={{
                      color: '#fff',
                      position: 'absolute',
                      top: '50%',
                      left: '25%',
                      marginTop: '-8px',
                      marginLeft: '-10px',
                      zIndex: '9'
                    }} />
                }
                <button
                  className={cn("btn btn_accent", {
                    btn_disabled: approveDriverReq.isLoading,
                  })}
                  onClick={async () => {
                    const result = await approveDriver({ _id: driver._id });
                    handleStatus(
                      result,
                      "approved",
                      "driver active successfully"
                    );
                  }}
                >
                  {!approveDriverReq.isLoading ?
                    (<FaCheck className="btn__icon" />) :
                    <div style={{ width: '25%' }}></div>
                  }
                  Active
                </button>
              </div>
            )}
            {checkForOffBoardDriver && (
              <button
                className={cn("btn btn_reject")}
                onClick={() => {
                  actions.modal.openReason({
                    obj: driver,
                    title: "OFF__BOARD__DRIVER",
                  });
                }}
              >
                Offboard
              </button>
            )}

            {checkForOnBoardDriver && (
              <div style={{ position: 'relative' }}>
                <button
                  className={cn("btn btn_accent", {
                    btn_disabled: approveDriverReq?.isLoading,
                  })}
                  onClick={async () => {
                    const result = await approveDriver({ _id: driver._id });
                    handleStatus(result, "onboard");
                  }}
                >
                  Onboard
                  {approveDriverReq?.isLoading && <div style={{ width: '25px' }}></div>}
                </button>
                {approveDriverReq?.isLoading && (
                  <CircularProgress size={18}
                    sx={{
                      color: '#fff',
                      position: 'absolute',
                      top: '50%',
                      left: '83%',
                      marginTop: '-8px',
                      marginLeft: '-10px',
                    }} />
                )}
              </div>
            )}

            {checkForMakingDriverInactive && (
              <button
                className={cn("btn btn_reject")}
                onClick={() => {
                  actions.modal.openReason({
                    obj: driver,
                    title: "INACTIVE__DRIVER",
                  });
                }}
              >
                Inactive
              </button>
            )}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CustomModal;
